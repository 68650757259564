import BlogCards from "../components/blogs/BlogsCards.js";
import BusinessOpportunity from "../components/businessopportunity/businessopportunity.js";
import ServiceCard from "../components/cards/ServiceCard.js";
import CallToAction from "../components/cta/CallToAction.js";
import Policies from "../components/policy/Policies.js";
import Filters from "../components/shop/Filters.js";
import Pagination from "../components/shop/Pagination.js";
import Product from "../components/shop/Product.js";
import CartIcon from "../components/shop/cart/CartIcon.js";
import CartItem from "../components/shop/cart/InfoItems/CartItem.js";
import CartTable from "../components/shop/cart/table/CartTable.js";
import ShippingForm from "../components/shop/shipping/ShippingForm.js";
import Totals from "../components/shop/totals/Totals.js";
import {filterProducts} from "../components/shop/utils/filterProducts.js";
import {headerEvents} from "../events/headerEvents.js";
import {shopEvents} from "../events/shopEvents.js";
import {globalState, setState, updateTutorials} from "../model/Model.js";
import {getAuthApi} from "../model/api/auth.js";

import {router, routing} from "../router/Router.js";
import {initPOPUP} from "../utility/js-include.js";
import {lazyLoad} from "../utility/observing.js";
import View from "../view/View.js";

export function Controller() {
  class _controller_ extends View {
    constructor() {
      super();
      this.state = globalState;
      setState = setState;
      this.selectedCategory = "";
      this.query = "";
      // Define your routes and corresponding content elements
      // this.routes = {
      //   "/": document.getElementById("home"),
      //   "/shop": document.getElementById("shop"),
      // };

      // this.view = View;
    }

    init() {
      // FIND QUERIES
      this.query = window.location.href?.split("=")[1];

      // INIT HISTORY STATE
      // history.replaceState({}, "", window.location.href);

      setState("updatePage", window.location.pathname);

      // INITIALIZE VIEWS
      this.screens(
        this.state.page,
        this.state.page + window.location.search,
        this.state
      );

      // INIT CALL TO ACTION
      CallToAction.init();

      // REGISTER EVENTS
      this.regEvents();
    }

    async views(id, update) {
      const {shop} = this.state;
      let paginatedProducts;
      let fiilterUI = new Filters();
      switch (id) {
        case "/":
          CartIcon.init(this.state.shop.cart.length);
          this.pageIsReady();
          if (!globalState.tutorials.list) {
            const result = await getAuthApi("/stories/posts");

            if (result?.status === "success") {
              updateTutorials(result?.data?.posts);
            } else {
              updateTutorials(undefined);
            }
          }
          // INIT CART
          await ServiceCard.init(globalState.tutorials.list);
          // await BlogCards.init(globalState.tutorials.list);

          break;
        case "/shop":
          // INIT POPUP
          // initPOPUP();
          // INIT CART
          CartIcon.init(this.state.shop.cart.length);
          // INITIALIZE SELECTED PRODUCTS
          let selectedProducts = this.state.shop.products.filter(
            (product) =>
              product.category === this.state.shop.filter.category || product
          );
          setState("updateSelectedProducts", selectedProducts);

          // Update Query
          this.query = window.location.href?.split("=")[1];

          if (this.query) {
            this.query = this.query.split("-").join(" ");
            setState("updateCategory", this.query);

            selectedProducts = filterProducts(
              selectedProducts,
              this.state.shop.filter
            );

            // IF QUERY Save the filtered product
            setState("updateSelectedProducts", selectedProducts);
          }

          // INITIALIZE PAGINATION
          if (update) {
            paginatedProducts = new Pagination().paginate(
              shop.page,
              shop.productsPerPage,
              shop.selectedProduct
            );
          } else {
            paginatedProducts = new Pagination().init(
              shop.page,
              shop.productsPerPage,
              shop.selectedProduct
            );
          }

          setState("updateShop", {
            prop: "paginatedProducts",
            value: paginatedProducts,
          });

          this.render(
            "filter_container",
            fiilterUI.filters(shop.paginatedProducts)
          );
          this.render(
            "product_list",
            new Product().mockup(shop.paginatedProducts)
          );

          this.pageIsReady();

          break;
        case "/product":
          // INIT CART
          CartIcon.init(this.state.shop.cart.length);
          this.pageIsReady();
          break;
        case "/cart":
          // INIT CART
          CartIcon.init(this.state.shop.cart.length);
          // INIT CART
          CartTable.init();
          this.pageIsReady();
          break;
        case "/shipping":
          document.querySelector("header")?.classList.add("hide-it");
          document.querySelector(".footer_")?.classList.add("hide-it");
          // Init Cart Items
          CartItem.init();

          // INIT TOTALS
          Totals.render();

          // HandleShopForm
          ShippingForm.init();
          this.pageIsReady();
          break;
        case "/confirm_details":
          document.querySelector("header")?.classList.add("hide-it");
          document.querySelector(".footer_")?.classList.add("hide-it");
          // Init Cart Items
          CartItem.init();

          // INIT TOTALS
          Totals.render();
          this.pageIsReady();
          break;
          // case "/blog":
          this.pageIsReady();
          break;
        case "/payments":
          document.querySelector("header")?.classList.add("hide-it");
          document.querySelector(".footer_")?.classList.add("hide-it");
          // Init Cart Items
          CartItem.init();

          // INIT TOTALS
          Totals.render();
          this.pageIsReady();
          break;
        case "/businessopportunity":
          BusinessOpportunity.init();
          this.pageIsReady();
          break;
        case "/blog":
          this.pageIsReady();
          break;
        case "/privatepolicy":
          Policies.init();
          this.pageIsReady();
          break;
        case "shop_filter":
          // INITIALIZE PAGINATION

          this.render(
            "product_list",
            new Product().mockup(shop.filteredProduct)
          );
          break;
        case "shop_sorting":
          // INITIALIZE PAGINATION

          this.render(
            "product_list",
            new Product().mockup(shop.filteredProduct)
          );
          break;
        case "search_result":
          this.render(
            "search__keyword",
            `(${this.state.shop?.["searchValue"] || "..."})`
          );
          this.render(
            "search_product_list",
            `${
              this.state.shop?.["searchValue"]
                ? new Product().mockup(this.state.shop?.["searchResults"])
                : new Product().notification(
                    `
                  No Products Found! <br /><br />
                <p>
                  Most searched products: <br />
                  <br />
                  <button
                    class="search__hints"
                    data-value="Formula 1 shake"
                    data-id="search_hints"
                  >
                    Formula 1 shake
                  </button>
                  <button
                    class="search__hints"
                    data-value=" Aloe"
                    data-id="search_hints"
                  >
                    Aloe
                  </button>
                  <button
                    class="search__hints"
                    data-value="Tea"
                    data-id="search_hints"
                  >
                    Tea
                  </button>
                </p>
                    `
                  )
            }`
          );
          break;

        default:
          break;
      }
    }

    pageIsReady() {
      // REMOVE SPLASH SCREEN
      this.elem("splash_screen").classList.add("hide-it");

      // Lazy load images
      lazyLoad("img[data-src]", (image) => {
        const src = image.getAttribute("data-src");
        image.setAttribute("src", src);
        image.removeAttribute("data-src");
        image.parentNode.classList.remove("content-is-loading");
      });

      // Lazy load iframes
      lazyLoad("iframe[data-src]", (iframe) => {
        const src = iframe.getAttribute("data-src");
        iframe.setAttribute("src", src);
        iframe.removeAttribute("data-src");
        image.parentNode.classList.remove("content-is-loading");
      });
    }

    async screens(path, route, props, is_from) {
      // console.log("DO WE GET HERE");
      switch (path) {
        case "/accounts":
          // ROUTING
          routing(path, route, [props]);
          // router(path, props);
          // Render
          // await this.views(path);

          // REMOVE LOADER
          this.elem("splash_screen").classList.add("hide-it");

          break;
        case "/":
          // ROUTING
          routing(path, route, [props]);
          // router(path, props);
          // Render
          await this.views(path);

          // REMOVE LOADER
          this.elem("splash_screen").classList.add("hide-it");

          break;
        case "/shop":
          // RESET FILTER
          setState("resetFilters", {});

          // console.log(globalState.shop.filter);

          // ROUTING
          routing(path, route, [props]);

          // // Change query
          // this.query = window.location.href?.split("=")[1];

          // if (this.query) {
          //   this.query = this.query.split("-").join(" ");
          //   setState("updateCategory", this.query);
          // }
          // Render
          await this.views(path);

          break;
        case "/product":
          // ROUTING
          routing(path, route, this.state.shop.products);

          // INVOKE VIEWS
          await this.views(path);

          break;
        case "/cart":
          // ROUTING
          routing(path, route, [props]);

          // INVOKE VIEWS
          await this.views(path);

          break;
        case "/shipping":
          // ROUTING
          routing(path, route, [props]);

          // INVOKE VIEWS
          await this.views(path);

          break;
        case "/confirm_details":
          // ROUTING
          routing(
            path,
            route,
            JSON.parse(localStorage.getItem(globalState.shop.shippingId))
          );

          // INVOKE VIEWS
          await this.views(path);

          break;
        case "/payments":
          // ROUTING
          routing(path, route, globalState.shop.getLocalShipping());

          // INVOKE VIEWS
          await this.views(path);

          break;
        case "/blog":
          // ROUTING

          routing(path, route, globalState);

          // INVOKE VIEWS
          // await this.views(path);

          break;
        case "/weightgain":
          // ROUTING

          routing(path, route, globalState);

          console.log(document.getElementById("call_to_action"));

          // INVOKE VIEWS
          await this.views(path);

          break;
        case "/privatepolicy":
          // ROUTING

          routing(path, route, globalState);

          // INVOKE VIEWS
          await this.views(path);

          break;
        case "/businessopportunity":
          // ROUTING

          routing(path, route, globalState);

          // INVOKE VIEWS
          await this.views(path);

          break;

        default:
          break;
      }
    }

    regEvents() {
      window.addEventListener("click", (e) => this.handleEvents(e));
      window.addEventListener("change", (e) => this.handleEvents(e));
      window.addEventListener("mouseover", (e) => this.handleEvents(e));
      window.addEventListener("input", (e) => this.handleEvents(e));
      window.addEventListener("popstate", (e) => this.handleEvents(e));
    }

    handleEvents(e) {
      switch (e.type) {
        case "click":
          shopEvents(e).handleClick(this);
          headerEvents(e).handleClick(this);

          break;
        case "change":
          shopEvents(e).handleChange(this);
          break;
        case "mouseover":
          headerEvents(e).handleHover(this);
          break;
        case "input":
          shopEvents(e).handleInputs(this);
          break;
        case "popstate":
          this.elem("splash_screen")?.classList.remove("hide-it");
          this.elem("modal_wrapper")?.classList.add("hide-it");

          this.elem("cancel_search_mobile")?.classList.add("hide-it");
          this.elem("mobile_search")?.classList.add("hide-it");
          this.elem("search_results")?.classList.add("translate-up");

          console.log(e);

          const targetPath = window.location.pathname;
          const targetRoute = window.location.pathname + window.location.search;

          // console.log(e.target.getAttribute("href"));
          // console.log(e.target.pathname);

          console.log(targetPath);
          console.log(targetRoute);

          setState("updatePage", targetPath);

          this.screens(targetPath, targetRoute, globalState, "pop_");
          this.elem("nav")?.classList.add("mobile__menu");

          // window.scroll({
          //   top: 0,
          // });
          break;

        default:
          break;
      }
    }
  }

  return new _controller_().init();
}
