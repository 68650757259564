import {globalState} from "../../model/model.js";

export default class Sorting {
  sorting(sortSelect, product) {
    // const {filteredProduct, selectedProduct} = state.shop;

    return this.handleSort(sortSelect, product);
  }

  handleSort(sortSelect, products) {
    const selectedValue = sortSelect;

    let sortedProducts;

    if (selectedValue === "price-low") {
      sortedProducts = products.slice().sort((a, b) => a.price - b.price);
    } else if (selectedValue === "price-high") {
      sortedProducts = products.slice().sort((a, b) => b.price - a.price);
    } else if (selectedValue === "featured") {
      sortedProducts = products.filter((product) => product.featured === true);
    } else {
      sortedProducts = products;
    }

    return sortedProducts;

    // displayProducts(sortedProducts);
  }

  mockup() {
    return `<select id="sort-select">
        <option value="default">Default</option>
        <option value="price-low">Price: Low to High</option>
        <option value="price-high">Price: High to Low</option>
        <option value="featured">Featured</option>
      </select>`;
  }
}
