import {_blogs} from "./data/blogs.js";
import {_newsletter} from "./data/newletter.js";
import {_private} from "./data/policy/private.js";
import {_shop} from "./data/shop.js";

export const globalState = {
  tokenID: "mamakhe_s_tokens",
  environmentID: "prod",
  page: window.location.pathname,
  home: {},
  shop: _shop,
  tutorials: {
    list: undefined,
  },
  writing: {
    data: {
      title: undefined,
      imgCover: undefined,
      description: undefined,
      content: {},
    },
  },
  blogs: _blogs,
  newsletter: _newsletter,
  policies: {
    private: _private,
  },

  currentState: {},
};

export function updateTutorials(data) {
  globalState.tutorials.list = data;
}

export function updateWritingData(data) {
  for (let prop in globalState.writing.data) {
    if (prop === data?.id) {
      globalState.writing.data[prop] = data?.value;
    }
  }

  console.log(globalState.writing.data);
}

class SetState {
  // HEADER
  updatePage(val) {
    globalState.page = val;
  }

  // CurrentState
  updateCurrentState(val) {
    globalState.currentState[val?.prop] = val?.value;
  }

  // UPDATE PRODUCTS
  updateSelectedProducts(val) {
    globalState.shop.selectedProduct = val;
  }

  resetFilters() {
    globalState.shop.filter = {
      category: "",
      selectedSizes: [],
      selectedFlavors: [],
      selectedPriceRange: {min: undefined, max: undefined},
      featured: false,
      sale: false,
    };
  }
  updateFilteredProducts(val) {
    globalState.shop.filteredProduct = val;
  }

  updateShop(val) {
    globalState.shop[val.prop] = val.value;
  }

  updateCategory(val) {
    globalState.shop.filter["category"] = val;
  }

  updateShopPage(val) {
    globalState.shop.page = val;
  }

  // FILTER
  updateFilter({id, val, value}) {
    for (let prop in globalState.shop.filter) {
      // Since size and flavor are arrays
      if (
        (id === "selectedSizes" && prop === id) ||
        (id === "selectedFlavors" && prop === id)
      ) {
        if (globalState.shop.filter[prop].includes(val)) {
          let index = globalState.shop.filter[prop].indexOf(val);
          globalState.shop.filter[prop].splice(index, 1);
        } else {
          globalState.shop.filter[prop].push(val);
        }
      } else if (id === "selectedPriceRange" && prop === id) {
        const [min, max] = val.split("-");

        globalState.shop.filter[prop].min = min;
        globalState.shop.filter[prop].max = value;
      } else if (id === "sale" && prop === id) {
        globalState.shop.filter[prop] = !globalState.shop.filter[prop];
      }
    }
  }

  // CART
  addToCart(cart) {
    globalState.shop.cart = cart;
  }

  // Shipping
  setShipping(shipping) {
    globalState.shop.shipping = shipping;
  }

  setNewsletter(signup) {
    globalState.newsletter = signup;
  }

  add({prop, value}) {
    globalState.shop[prop] = value;
  }
}

function setState(prop, val) {
  new SetState()?.[prop](val);
}

export {setState};
