export class _ev {
  constructor() {}

  async handleEvents(e) {
    const target = e.target;
    const id = e.target.id;
    const type = e.type;
    const value = e.target.value;
    const _id = target.dataset.id;
    const _value = target.dataset.value;
    const _item = target.dataset.item;

    console.log(type);
    switch (type) {
      case "click":
        switch (_id) {
          case "credit_inputs":
            break;

          default:
            break;
        }
        break;

      default:
        break;
    }
  }
}
