import {globalState} from "../../../model/model.js";
import View from "../../../view/View.js";

const CartIcon = (function () {
  const state = {
    cartLength: 0,
  };

  const view = new View();

  const init = () => {
    state.cartLength =
      JSON.parse(localStorage.getItem(globalState.shop.cartId))?.length ?? 0;

    //   Change the length
    view.render("cart_icon", mockup());
    view.render("cart_icon_mobile", mockup());
  };

  function mockup() {
    return `
            <a data-id="link" href="/cart" class="cart-btn">
          <span data-id="link" data-item="/cart" data-value="/cart" id="cart_no">${
            state?.cartLength
          }</span>
          ${
            state?.cartLength
              ? `<ion-icon data-item="/cart" data-value="/cart" data-id="link" id="cart__icon" class="cart-icon" name="cart"></ion-icon>`
              : `<ion-icon data-item="/cart" data-value="/cart" data-id="link" id="cart__icon" class="cart-icon" name="cart-outline"></ion-icon>`
          }
        </a>
        `;
  }

  return {
    init,
  };
})();

export default CartIcon;
