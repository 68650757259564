function loadIcons() {
  let scriptEle = document.createElement("script");

  scriptEle.setAttribute(
    "src",
    "https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js"
  );
  scriptEle.setAttribute("type", "module");
  scriptEle.setAttribute("async", true);

  document.body.appendChild(scriptEle);

  scriptEle.addEventListener("load", function () {
    console.log("SUCCESS");
  });
  scriptEle.addEventListener("error", function () {
    console.log("FAIL");
  });
}

export {loadIcons};
