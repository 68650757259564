// COMPONENTS
import Quill from "quill";

import "./CreateStory.scss";
import {_ev} from "./events/_ev";
import _views from "./views/_views";

const CreateStory = (function () {
  const localState = {
    // local data relevant to the components
  };

  const view = new _views();

  const handlers = new _ev();

  const toolbarOptions = [
    [
      "bold",
      "italic",
      "underline",
      {color: []},
      // {background: []},
      {font: []},
      "image",
      "link",
      // "video",
    ], // toggled buttons

    [
      {size: ["small", false, "large", "huge"]},
      {header: [1, 2, 3, 4, 5, 6]},
      {align: []},
      {list: "bullet"},
      {list: "ordered"},
    ],

    // [{script: "sub"}, {script: "super"}], // superscript/subscript
  ];

  let quillLib = new Quill("#editor", {
    modules: {
      toolbar: toolbarOptions,
    },
    videoEmbed: true, // Enable the Video Embed module
    theme: "snow",
  });

  const init = async () => {
    // INIT QUILL

    view.render("writing_block-information", view.postInfo());
    view.elem("write_block").classList.remove("hide-it");
    view.elem("splash_screen").classList.add("hide-it");
    setTimeout(() => {
      console.log(document.querySelector(".ql-tooltip"));
    }, 5000);
  };

  return {
    init,
    handleEvents: handlers.handleEvents,
    quillLib,
  };
})();

function events({handleEvents}) {
  window.addEventListener("click", handleEvents);
  window.addEventListener("input", handleEvents);
  window.addEventListener("submit", handleEvents);

  // Add more events
}

events(CreateStory);

export default CreateStory;
