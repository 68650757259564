import {globalState} from "../../../../model/Model";

let baseUrl = undefined;

const MODE = "prod";

if (MODE === "prod") {
  baseUrl = "https://wellnesswithbridgette-backend.onrender.com/api/v1/";
} else {
  baseUrl = "http://localhost:8080/api/v1/";
}

async function authApi(endpoint = "", data) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
    },
    body: JSON.stringify(data),
  };

  const res = await fetch(baseUrl + endpoint, options);

  const resData = await res.json();

  return resData;
}
async function postApi(endpoint = "", data) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  const res = await fetch(baseUrl + endpoint, options);

  const resData = await res.json();

  return resData;
}

async function getApi(endpoint = "", data) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const res = await fetch(baseUrl + endpoint, options);

  const resData = await res.json();

  return resData;
}
async function updateApi() {}
async function deleteApi() {}

export {postApi, getApi, updateApi, deleteApi};
