import View from "../../../view/View";

class _views extends View {
  constructor(props) {
    super(props);
  }

  mockup(props) {
    return props
      .map(
        (service) => `<div id="${service?._id}" class="service__card">
      <div class="header content-is-loading">
      <img src="${service?.imgCover}" class="img-contain" alt="wellnesswithbridgette ${service?.description}" />

      </div>
      <div class="body">
        <h2>${service?.title}</h2>
        <p>
         ${service?.description}
        </p>
      </div>
      <div class="footer">

      
        <a data-id="link" href="/blog?post=${service?.title}&id=${service?._id}" data-value="id=${service?._id}" class="learn-more" target="_blank">
          Learn More
        </a>
      </div>
    </div>`
      )
      .join(``);
  }

  posts(props) {
    return `<div class="posts__container">${props
      ?.map((item, i) => {
        return this.tutorialCard(item, i);
      })
      .join(``)}</div>`;
  }

  tutorialCard(item, i) {
    return `<article class="tutorial__card">
      <a href="/blog?title=${item?.title
        ?.split(" ")
        ?.join("-")
        ?.toLowerCase()}&id=${
      item?._id
    }" class="tutorial__card-header" href="#">
        <img
          src="${item?.imgCover}"
          alt="afripavostudio tutorials"
          
        />
        </a>
        <div class="tutorial__card-footer">
          <h2 class="tutorial__card-title">${item?.title}</h2>
          <p class="tutorial__card-description">${item?.description}</p>
          <a class="tutorial__card-type">Wellness</a>
        </div>
    </article>`;
  }
}
export default _views;
