import View from "../../view/View";

const BusinessOpportunity = (function () {
  const state = {};

  // Methods
  const init = () => {
    new View().render("business_opportunity", mockup());
  };

  // View
  const hero = () => {
    return `  <section class="business__hero">
        <div class="main__container">
          <h1 class="title">Unlock Your Potential with Herbalife</h1>
           <p>Herbalife products and business opportunity are exclusively available through Independent Herbalife distributors and the products are NOT available in stores.</p>

<p>Please complete the form below to find out more on the Herbalife products and business opportunity.</p>

          <p class="description hide-it">
          Are you ready to take charge of your financial future while promoting health and wellness? Look no further! Welcome to Herbalife - an empowering business opportunity that lets you become your own boss and make a positive impact on people's lives.
          </p>
         
          <p></p>
          <div class="video-wrapper">
          <div class="business__img">
            <img src="./images/team_photo.jpeg" alt="wellnesswithbridgette business opportunity" class="img" />
          </div>
          </div>
          <h2>Why Choose Herbalife Business?</h2>
          ${card()}
 
        </div>
      </section>
        `;
  };

  const form = () => {
    return `
        <section class="form-section">
        <div class="main__container">
          <h2 class="section-title">Get Started Today</h2>
          <p class="section-description">
            Fill out the form below to receive more information about joining
            Herbalife and starting your business.
          </p>
          <a style="margin: 50px 0; max-width: 300px" href="https://form.jotform.com/231153900930548" target="_blank" class="btn btn-long">Learn More</a>
         
        </div>
      </section>
        `;
  };

  function card() {
    return `<div class="business__container">
        <div class="business__card">
          <h2>Proven Success</h2>
          <p>
            With over 40 years of global success, Herbalife has helped countless
            individuals achieve their goals, both in health and financial
            independence.
          </p>
        </div>
        <div class="business__card">
          <h2>Quality Products</h2>
          <p>
            Represent a world-renowned brand known for its top-quality
            nutritional supplements, weight management products, and more.
          </p>
        </div>
        <div class="business__card">
          <h2>Flexibility</h2>
          <p>
            Work on your terms, part-time or full-time, from the comfort of your
            home or while traveling.
          </p>
        </div>
        <div class="business__card">
          <h2>Extensive Support</h2>
          <p>
            Benefit from comprehensive training, mentorship, and marketing tools
            to kickstart your journey as a Herbalife distributor.
          </p>
        </div>
      </div>`;
  }

  function mockup() {
    return `
      ${hero()}
      ${form()}
    `;
    // return `${businessOpportunityForm()}`;
  }

  function businessOpportunityForm(props) {
    return ` <form id="herbalifeForm">
    <!-- Function to create a radio input -->
    ${createRadioInput(
      "contactedBefore",
      "Have you contacted an Independent Herbalife Member before?",
      ["Yes", "No"]
    )}

    <!-- Function to create a text input -->
    ${createTextInput("firstName", "First Name")}
    ${createTextInput("lastName", "Last Name")}
    ${createTextInput("email", "Email Address")}
    ${createTextInput("phone", "Phone Number")}
    
    <!-- Function to create a select input -->
    ${createSelectInput("country", "Country", ["SOUTH AFRICA - English"])}
    ${createSelectInput(
      "state",
      "State",
      []
    )} <!-- Populate with state options -->
    
    <!-- Function to create a text input -->
    ${createTextInput("city", "City")}
    ${createTextInput("zipcode", "Zipcode")}
    
    <!-- Function to create a select input -->
    ${createSelectInput("age", "Your Age", [
      "Under 18",
      "18-25",
      "26-35",
      "36-45",
      "46-55",
      "56 and over",
    ])}
    ${createSelectInput("contactTime", "Best time to contact you?", [
      "Morning",
      "Afternoon",
      "Evening",
    ])}
    
    <!-- Function to create radio inputs -->
    ${createRadioInput("gender", "Gender", ["Male", "Female"])}
    
    <!-- Function to create a select input -->
    ${createSelectInput("purchaseTime", "Approximate time of purchase", [
      "Not Sure",
      "Within 1 Month",
      "1-3 Months",
      "3-6 Months",
      "More than 6 Months",
    ])}
    
    <!-- Function to create checkbox inputs -->
    ${createCheckboxInput("productInterests", "Product Interests", [
      "Weight Management",
      "Basic & Targeted Nutrition",
      "Energy & Fitness",
      "Personal Care",
    ])}
    ${createCheckboxInput("businessOpportunity", "Business Opportunity")}
    
    <!-- Function to create a select input -->
    ${createSelectInput(
      "howHeard",
      "How did you hear about our products?",
      []
    )} <!-- Populate with options -->
    
    <!-- Function to create a textarea input -->
    ${createTextareaInput("comments", "Comments")}
    
    <button type="submit">Submit</button>
  </form>`;
  }

  // Inputs
  // Create a radio input
  function createRadioInput(id, label, options) {
    const radioInputs = options
      .map(
        (option) => `
    <label>
      <input type="radio" name="${id}" value="${option}" />
      ${option}
    </label>
  `
      )
      .join("");

    return `
    <fieldset>
      <legend>${label}</legend>
      ${radioInputs}
    </fieldset>
  `;
  }

  // Create a text input
  function createTextInput(id, label) {
    return `
    <label for="${id}">${label}</label>
    <input type="text" id="${id}" name="${id}" />
  `;
  }

  // Create a select input
  function createSelectInput(id, label, options) {
    const selectOptions = options
      .map((option) => `<option value="${option}">${option}</option>`)
      .join("");

    return `
    <label for="${id}">${label}</label>
    <select id="${id}" name="${id}">
      ${selectOptions}
    </select>
  `;
  }

  // Create a checkbox input
  function createCheckboxInput(id, label, options = []) {
    const checkboxInputs = options
      .map(
        (option) => `
    <label>
      <input type="checkbox" name="${id}" value="${option}" />
      ${option}
    </label>
  `
      )
      .join("");

    return `
    <fieldset>
      <legend>${label}</legend>
      ${checkboxInputs}
    </fieldset>
  `;
  }

  // Create a textarea input
  function createTextareaInput(id, label) {
    return `
    <label for="${id}">${label}</label>
    <textarea id="${id}" name="${id}" rows="4"></textarea>
  `;
  }

  return {
    hero,
    form,
    mockup,
    init,
  };
})();

export default BusinessOpportunity;
