import View from "../../../../view/View.js";
import {globalState, setState} from "../../../../model/model.js";

class Items extends View {
  constructor(props) {
    super(props);
  }

  mockup(props) {
    return this.cartItem(props);
  }

  cartItem(props) {
    return `   <h2>Cart Items</h2>${props
      ?.map((item) => {
        if (item) return this.row(item);
      })
      .join(``)}`;
  }

  row(props) {
    return `
    <div class="cart-item">
          ${this.img(props)}
          ${this.details(props)}
          ${this.price(props)}
      </div>`;
  }

  img({id, image, quantity}) {
    let alt = image?.split("/")[2]?.split(".")[0];
    return ` <div class="img-thumbnail content-is-loading">
        <span class="quantity_badge">${quantity}</span>
        <img
          data-id="img_slider"
          data-value="${id}"
          data-src="${image}"
          alt="${alt}"
          class="img-cover"
        />
      </div>`;
  }

  details({title, size}) {
    return `<div class="product-id center">
      <h3>${title}</h3>
      <p>Size: ${size}</p>
    </div>`;
  }

  price({price}) {
    return `<h3>R${price}</h3>`;
  }
}

const CartItem = (function () {
  const state = {
    cart() {
      return JSON.parse(localStorage.getItem(globalState.shop.cartId));
    },
  };
  const view = new View();

  const init = () => {
    view.render("cart_items", new Items().mockup(state.cart()));
  };

  return {
    init,
  };
})();

export default CartItem;
