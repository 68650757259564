import View from "../../../view/View.js";

class LoginViews extends View {
  constructor(props) {
    super(props);
  }

  //   init(props) {}

  mockup(type) {
    return `
    <div id="login_screen" >
      ${this.loginBanner()}
      ${type === "register" ? this.register() : this.login()}
    </div>
        `;
  }

  login() {
    return `
    <div id="login_form">
          <form data-id="login" class="login-form">
            <h1 class="form-title">Login</h1>
            <input id="email" type="email" class="form-input" placeholder="Email">
            <input id="password" style="margin-bottom: 0px" type="password" class="form-input" placeholder="Password">
            <b id="err_inputs"></b>
            <button type="submit" class="form-button">Login</button>
           
            </form>
            </div>
    `;
  }

  loginBanner() {
    return `
    <div id="login_banner">
      <img src="./images/loginImg.jpg" alt="login banner image" class="img-cover" />
    </div>
    `;
  }

  register() {
    return `
      <div id="registration_form" >
        <form data-id="registration" class="signup-form">
          <h1 class="form-title">Create an Account</h1>
          <input  id="username" id="username" type="text" class="form-input" placeholder="User Name">
          <input id="email" id="email" type="email" class="form-input" placeholder="Email">
          <span style="font-size: 12px">Minimum length: 8 characters</span>
          <input id="password" id="password" type="password" class="form-input" placeholder="Password">
          <b id="err_inputs"></b>
          <h4>Company Details: </h4>
          <input  id="companyName" type="text" class="form-input" placeholder="Company Name">
          <input  id="businessType" type="text" class="form-input" placeholder="Business Type">
          <input  id="phoneNumber" type="text" class="form-input" placeholder="Phone Number">
          <button type="submit" class="form-button">Sign up</button>
          <p class="form-text">Already have an account? <a href="/login" class="link_info">Log in</a></p>
        </form>
      </div>
    `;
  }
}
export default LoginViews;
