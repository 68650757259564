import Accordion from "../accordion/Accordion.js";

export default class Filters {
  filters(products) {
    // console.log(products);
    return `
      ${this.flavors(products)}
      ${this.sizes(products)}
      ${this.priceRange(products)}
      ${this.sale(products)}
    `;
  }

  sizes(products) {
    function getUniqueSizes() {
      const sizes = new Set();
      products.forEach((product) => {
        product.sizes.forEach((size) => sizes.add(size));
      });
      return Array.from(sizes);
    }

    const items = getUniqueSizes()
      ?.map(
        (item) =>
          `<div class="items">
        <label>${item}</label>
        <input id="selectedSizes" type="checkbox" data-id="filter_input" data-value="${item}" />
      </div>`
      )
      .join(``);

    return Accordion.accordion_v1("Size", items);
  }

  flavors(products) {
    function getUniqueFlavors() {
      const flavors = new Set();
      products.forEach((product) => {
        flavors.add(product.flavor);
      });
      return Array.from(flavors);
    }

    const items = getUniqueFlavors()
      ?.map(
        (item) =>
          `<div class="items">
        <label>${item}</label>
        <input id="selectedFlavors" type="checkbox" data-id="filter_input" data-value="${item}" />
      </div>`
      )
      .join(``);

    return Accordion.accordion_v1("Flavors", items);
  }

  priceRange(products) {
    // Get minimum and maximum price range from the product data
    let isSame = false;
    function getPriceRange() {
      let minPrice = Infinity;
      let maxPrice = -Infinity;
      products.forEach((product) => {
        if (product.price < minPrice) {
          minPrice = product.price;
        }
        if (product.price > maxPrice) {
          maxPrice = product.price;
        }
      });

      if (minPrice === maxPrice) {
        isSame = true;
      }
      return {minPrice, maxPrice};
    }

    const items = ({minPrice, maxPrice}) => `<div class="price__items">
        <label>R${minPrice}</label>
        <label>R${maxPrice}</label>
        <input id="selectedPriceRange" type="range" data-id="filter_input" min="${minPrice}" max="${maxPrice}" value="${maxPrice}" data-value="${
      minPrice + "-" + maxPrice
    }" stepper="2" />
      </div>`;

    return Accordion.accordion_v1("Price", items(getPriceRange()));
  }

  featured(products) {
    // Get featured products
    function getFeaturedProducts() {
      return products.filter((product) => product.featured === true);
    }

    const items = getFeaturedProducts()
      ?.map(
        ({id}) =>
          `<div>
        <label>Size</label>
        <input type="checkbox" data-id="filter_input" data-value="${id}" />
      </div>`
      )
      .join(``);
  }

  sale(products) {
    // Get products on sale
    function getProductsOnSale() {
      return products.filter((product) => product.sale === true);
    }

    const items = `<div class="items">
        <label>On Sale</label>
        <input id="sale" type="checkbox" data-id="filter_input" data-value="sale" />
      </div>`;

    return getProductsOnSale()?.length > 0
      ? Accordion.accordion_v1("Sale", items)
      : "";
  }
}
