class Accordion {
  accordion_v1(title, items, styles, border) {
    return `<div class="accordion__container ${styles ? styles : ""} ${
      border ? border : ""
    }">
        <button data-id="accordion" data-value="${title}" class="header ${
      styles ? styles : ""
    }">
          <h3 data-id="accordion" data-value="${title}">
            ${title}
          </h3>
          <ion-icon
            id="${title}"
            data-id="accordion"
            data-value="${title}"
            class="icon-sm"
            name="chevron-down-outline"
          ></ion-icon>
        </button>
        <div id="accordion_${title}" class="body ${styles ? styles : ""}">
          ${items}
        </div>
      </div>`;
  }
}

export default new Accordion();
