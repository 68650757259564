import View from "../../view/View.js";
import {globalState, setState} from "../../model/model.js";
import CartNotification from "./cart/notification/CartNotification.js";
import CartIcon from "./cart/CartIcon.js";
import Totals from "./totals/Totals.js";

const _globalShop = (function () {
  // State
  const state = {
    products: globalState.shop.products.slice(),
    cart() {
      return JSON.parse(localStorage.getItem(globalState.shop.cartId)) ?? [];
    },
    quantity: 1,
    cartId: globalState.shop.cartId,
    productId() {
      let _id = window.location?.search?.split("=")?.[1];

      if (!_id) {
        throw Error(
          "No Product Matches this Id in our database, please go back to the shop page and selected the product you want to add to your cart."
        );
      }

      return _id;
    },
  };

  function setGlobalQuantity(val) {
    state.quantity = val;
  }

  const view = new View();

  async function handleClick(e) {
    const target = e.target;
    const id = e.target.id;
    const value = e.target.value;
    const _id = target.dataset.id;
    const _value = target.dataset.value;
    const item = target.dataset.item;

    let tempVal = 0;

    const view = new View();

    switch (_id) {
      case "pay_now":
        view.isDisplayed("processing", "hide-it");
        view.isDisplayed("modal_wrapper", "hide-it");
        view.elem("err").classList.add("hide-it");

        // get Total
        const total = Totals.calculateTotal().total;

        // API CALL
        const data = {
          amount: total * 100,
          currency: "ZAR",
          cancelUrl: "https://wellnesswithbridgette.com/payments",
          cart: state.cart(),
        };

        try {
          const res = await fetch(
            "https://payment-api-viaa.onrender.com/api/v1/payment",
            {
              method: "POST",
              headers: {"Content-Type": "application/json"},
              body: JSON.stringify(data),
            }
          );

          const resData = await res.json();

          if (resData?.status === "success") {
            const {data} = resData;

            window.location.href = data?.redirectUrl;
          } else {
            view.elem("processing").classList.add("hide-it");
            view.elem("err").classList.remove("hide-it");
          }
        } catch (error) {
          view.elem("processing").classList.add("hide-it");
          view.elem("err").classList.remove("hide-it");
          // view.elem("modal_wrapper").classList.add("hide-it");

          console.log(view.elem("processing"));
        }

        break;
      case "open_list_province":
        view.isDisplayed(`select_container_${_value}`, "hide-it");
        break;

      case "add_to_cart":
        let tempCart = state.cart();

        // Prepare the cart item
        let cartItem = filterProduct(state.products, _value).map(
          ({id, images, title, price, short_description, sizes}) => {
            return {
              id: id,
              image: images?.[0],
              title: title,
              description: short_description,
              price: price,
              size: sizes,
              quantity: state.quantity,
            };
          }
        );

        //Check if product in the cart
        let index = tempCart?.find((el) => {
          if (el?.id === _value) {
            // Change item quantity
            el.quantity = state.quantity;

            return el?.id === _value;
          }
        });

        if (index) {
        } else {
          // Push in item
          tempCart.push(cartItem?.[0]);
        }

        saveToLocal(tempCart);
        // CartIcon.init();

        break;
    }
  }

  function saveToLocal(cart, notify) {
    // Saves to local cart

    localStorage.setItem(state.cartId, JSON.stringify(cart));

    setState("addToCart", cart);

    CartIcon.init();

    // Once saved change the cart icons number and color
    if (notify === "quantity") {
      return;
    }

    if (notify !== "quantity") {
      // Show Cart Notification
      CartNotification.init(cart[0]);
    }
  }

  function filterProduct(products, _id) {
    return products?.filter((el) => el?.id === _id);
  }

  function addToCart(id) {
    return `
      <button
        id="product_add_to_cart"
        data-id="add_to_cart"
        data-value="${id}"
        class="add-bag-btn add-to-cart"
      >
        ADD TO BAG
      </button>
    `;
  }

  function quantityCounter(id, quantity) {
    return ` <div class="quantity-counter">
        <button
          data-value="${quantity}"
          data-item="${id}"
          data-id="quantity_down"
          class="icon-sm"
        >
          <ion-icon
            data-value="${quantity}"
            data-item="${id}"
            data-id="quantity_down"
            class="icon-sm"
            name="remove-outline"
          >
            -
          </ion-icon>
        </button>
        <p id="quantity_val_${id}">${quantity}</p>
        <button
          data-value="${quantity}"
          data-item="${id}"
          data-id="quantity_up"
          class="icon-sm"
        >
          <ion-icon  data-value="${quantity}"
          data-item="${id}" data-id="quantity_up" class="icon-sm" name="add-outline">
            +
          </ion-icon>
        </button>
      </div>`;
  }

  return {
    handleClick,
    addToCart,
    quantityCounter,
    saveToLocal,
    filterProduct,
    setGlobalQuantity,
  };
})();

window.addEventListener("click", _globalShop.handleClick);

export default _globalShop;
