import Footer from "../components/footer/Footer.js";
import Header from "../components/header/Header.js";
import Totals from "../components/shop/totals/Totals.js";
import View from "../view/View.js";

class Shipping extends View {
  constructor(props) {
    super(props);
    this.formValues = {
      provinces: [
        {
          id: "gauteng",
          value: "gauteng",
          label: "Gauteng",
        },
        {
          id: "limpompo",
          value: "limpompo",
          label: "Limpopo",
        },
        {
          id: "eastern Cape",
          value: "eastern Cape",
          label: "Eastern Cape",
        },
        {
          id: "western Cape",
          value: "western Cape",
          label: "Western Cape",
        },
        {
          id: "kwaZulu-Natal",
          value: "kwaZulu-Natal",
          label: "KwaZulu-Natal",
        },
        {
          id: "northern Cape",
          value: "northern Cape",
          label: "Northern Cape",
        },
        {
          id: "free State",
          value: "free State",
          label: "Free State",
        },
        {
          id: "north West",
          value: "north West",
          label: "North West",
        },
        {
          id: "mpumalanga",
          value: "mpumalanga",
          label: "Mpumalanga",
        },
      ],
    };
  }

  init(props) {
    // document.body.innerHTML = "";
    this.render("app", this.mockup());
    // document.body.insertAdjacentHTML("afterbegin", this.mockup());
  }

  mockup(props) {
    return `    
            <main>
      <div id="app">
        <!-- CONTENT -->
        <div class="product-wrapper">
          <div class="products">
            <div class="cart-items-header">
              <button data-id="open_order_summary" class="show-summary-btn">
                <p data-id="open_order_summary">Order summary</p>
                <ion-icon
                  id="order_chevron"
                  data-id="open_order_summary"
                  class="icon-md"
                  name="chevron-down-outline"
                ></ion-icon>
              </button>
              <p id="summary_total_mobile">R${
                Totals.calculateTotal().subtotal
              }</p>
            </div>
            <div class="container">
              <div class="left-container center-it" style="margin-top: 50px !important">
                
                 ${Header.checkout()}

                <div class="checkout-information">
                  <div class="row">
                    <p>Contact Information</p>
                    <p style="font-size: var(--font-12)">
                      Already have an account?
                      <a class="info-link" href="/login">Log in</a>
                    </p>
                  </div>
                  <form data-id="submit_shipping">
                    <div class="input-container">
                      <input
                        data-id="inputs"
                        type="email"
                        id="email"
                        required
                        class="inputs"
                        placeholder="Email"
                      />
                    </div>
                    <div class="row">
                      <p>Shipping Address</p>
                    </div>

                    <!-- <div class="input-container">
                      <div class="select-input">
                        <div class="input-info">
                          <p>country</p>
                          <p><strong>Gauteng</strong></p>
                        </div>

                        <button data-id="open-list" class="select-icons">
                          <p data-id="open-list">|</p>
                          <ion-icon
                            data-id="open-list"
                            name="chevron-down-outline"
                          ></ion-icon>
                        </button>
                      </div>
                    </div> -->
                    <div class="input-row">
                      <div class="input-container">
                        <input
                          data-id="inputs"
                          required
                          type="text"
                          id="name"
                          class="inputs"
                          placeholder="First Name"
                        />
                      </div>
                      <div class="pad-10"></div>
                      <div class="input-container">
                        <input
                          data-id="inputs"
                          required
                          type="text"
                          id="surname"
                          class="inputs"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div class="input-container">
                      <input
                        data-id="inputs"
                        required
                        type="text"
                        id="address"
                        class="inputs"
                        placeholder="Address"
                      />
                    </div>
                    <div class="input-container">
                      <input
                        data-id="inputs"
                        type="text"
                        id="apartment"
                        class="inputs"
                        placeholder="Apartment Number"
                      />
                    </div>
                    <div style="overflow: visible !important" class="input-container">
                    
                        
                        <div
                          data-id="open_list_province"
                          data-value="province"
                          class="select-input"
                        >

                             <div
                            data-id="inputs"
                            id="select_container_province"
                            class="select-options hide-it"
                          >
                            <ul>
                             ${this.formValues.provinces
                               .map((province) => {
                                 return ` <li  
                                      data-id="inputs"
                                       id="${province.id}"
                                       value="${province.value}"
                                       data-item="province"
                                       data-value="${province.value}"
                                       >
                                     <input
                                       data-id="inputs"
                                       id="${province.id}"
                                       type="radio"
                                       value="${province.value}"
                                       data-item="province"
                                       data-value="${province.value}"
                                       hidden
                                       name="province"
                                     />
                                     <label
                                      data-id="inputs"
                                       class="select-label"
                                       for="${province.id}"
                                       data-item="province"
                                       data-value="${province.value}"
                                     >
                                       ${province.label}
                                     </label>
                                   </li>`;
                               })
                               .join(``)}
                            </ul>
                          </div>


                          <div
                            data-id="open_list_province"
                            data-value="province"
                            class="input-info"
                          >
                            <p
                              data-id="open_list_province"
                              data-value="province"
                            >
                              Province
                            </p>
                            <p
                              data-id="open_list_province"
                              data-value="province"
                            >
                              <strong
                              data-id="open_list_province"
                              data-value="province"
                                id="province"
                                style="text-transform: capitalize"
                                >Select Province</strong
                              >
                            </p>
                          </div>

                          <button
                            type="button"
                            data-id="open_list_province"
                            class="select-icons"
                            data-value="province"
                          >
                            <p
                              data-value="province"
                              data-id="open_list_province"
                            >
                              |
                            </p>
                            <ion-icon
                              class="icon-sm"
                              data-value="province"
                              data-id="open_list_province"
                              name="chevron-down-outline"
                            ></ion-icon>
                          </button>

                     
                     
                      </div>
                    </div>
                    <i id="err_province" class="err"></i>
                    <div class="input-row">
                      <div class="input-container">
                        <input
                          data-id="inputs"
                          required
                          type="text"
                          id="city"
                          class="inputs"
                          placeholder="City"
                        />
                      </div>
                      <div class="pad-10"></div>

                      <div class="pad-10"></div>
                      <div class="input-container">
                        <input
                          data-id="inputs"
                          required
                          type="number"
                          id="postal"
                          class="inputs"
                          placeholder="Postal Code"
                        />
                      </div>
                    </div>
                    <div class="input-container">
                      <input
                        data-id="inputs"
                        required
                        type="number"
                        id="cell"
                        class="inputs"
                        placeholder="Cell"
                      />
                    </div>
                    <div class="checkout-getaway payment-checkout-getaway">
                      <div class="payment-supported">
                        <a data-id="/cart" href="/cart" class="btn-md light-btn info-link">
                          <ion-icon data-id="link" data-value="/cart" data-item="/cart"  name="arrow-back-outline"></ion-icon>
                          <span data-id="link" data-value="/cart" data-item="/cart" > Return to Cart </span>
                        </a>
                      </div>
                      <div class="checkout-container">
                        <button
                          
                          type="submit"
                          class="btn-md"
                        >
                          Continue to Shipping
                        </button>
                      </div>
                    </div>
                  </form>
                  <button data-value="/confirm_details" data-item="/confirm_details" data-id="link" class="hide-it" id="next__page"></button>
                  <p >
                    By clicking Continue to shipping, you agree to our
                    <a class="info-link" href="terms">terms</a> and
                    <a class="info-link" href="terms">privacy policy</a>.
                  </p>
                </div>
              </div>
              <div
                id="order_summary"
                class="right-container right-checkout-details translate-Y"
              >
                <div id="cart_items" class="cart-items"></div>
                <div class="line-divider-dark"></div>
                <div class="gift-card"></div>
                <!-- <div class="line-divider-dark"></div> -->
               <div class="total-summary" id="summary_container">

               </div>
            </div>
          </div>
        </div>

        <!-- CONTENT -->
      </div>
    </main>

   ${Footer.checkout()}
      `;
  }
}

export default Shipping;
