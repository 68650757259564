import View from "../view/View.js";

class Cart extends View {
  constructor(props) {
    super(props);
  }

  init(props) {
    this.render("app", this.mockup());
  }

  mockup(props) {
    return `
             <div class="product-wrapper">
          <div style="margin-top: 10px; padding: 10px;" class="products">
            <div class="container-col">
              <h2 class="cart-title">Cart</h2>
              <div class="cart-table">
                <div class="cart-table-header">
                  <div class="cart-table-cell-main">
                    <p>product</p>
                  </div>
                  <div class="cart-table-cell">
                    <p>Quantity</p>
                  </div>
                  <div class="cart-table-cell">
                    <p>Total</p>
                  </div>
                </div>
                <div class="line-divider"></div>
                <div id="cart_table_body" class="cart-table-body"></div>

                <!-- <div class="line-divider"></div> -->

                <div id="cart_table_footer" class="cart-table-footer"></div>
              </div>
              <div class="checkout-getaway">
                <div class="payment-supported">
                  <button data-id="back_btn" class="btn-md light-btn info-link hide-it">
                    <ion-icon data-id="back_btn" name="arrow-back-outline"></ion-icon>
                    <span id="" data-id="back_btn" style="margin-left: 10px">Go Back </span>
                  </button>
                </div>
                <div class="checkout-container">
                  <button id="checkout_btn" disabled style="background-color: gray; cursor: none;" class="btn btn-long ">CHECKOUT</button>
                  <a data-id="link" id="checkout_link"  href="/shipping" class="btn btn-long hide-it"> CHECKOUT </a>
                  <p>Shipping calculated at checkout</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        `;
  }
}
export default Cart;
