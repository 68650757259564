import {globalState} from "../../model/model.js";

class Search {
  searching(searchInputs, products) {
    return this.handleSearch(searchInputs, products);
  }

  handleSearch(searchInput, products) {
    const searchTerm = searchInput.toLowerCase();
    const searchedProducts = products.filter((product) => {
      const productName = product.title.toLowerCase();
      const productCategory = product.category.toLowerCase();

      return (
        productName?.includes(searchTerm) ||
        productCategory?.includes(searchTerm)
      );
    });

    return searchedProducts;
  }
}

export default Search;
