import QuizCard from "../components/quiz/QuizCard.js";
import {globalState, setState} from "../model/Model.js";
import {router, routing} from "../router/Router.js";

export function headerEvents(e) {
  const target = e.target;
  const id = e.target.id;
  const value = e.target.value;
  const _id = target.dataset.id;
  const _value = target.dataset.value;
  const _item = target.dataset.item;

  class _ev_ {
    constructor() {
      // super();
    }
    handleClick(self) {
      switch (_id) {
        case "take_quiz":
          QuizCard.init();
          self.elem("quiz__modal")?.classList.remove("hide-it");

          break;
        case "close_quiz_modal":
          self.elem("quiz__modal")?.classList.add("hide-it");

          break;
        case "newsletter_inputs":
          break;
        case "open_order_summary":
          self.elem("order_summary")?.classList.toggle("translate-Y");
          self.elem(`order_chevron`)?.classList.toggle("rotate-open");

          break;
        case "link":
          e.preventDefault();
          self.elem("splash_screen")?.classList.remove("hide-it");
          self.elem("modal_wrapper")?.classList.add("hide-it");

          self.elem("cancel_search_mobile")?.classList.add("hide-it");
          self.elem("mobile_search")?.classList.add("hide-it");
          self.elem("search_results")?.classList.add("translate-up");

          const targetPath = target.pathname ?? _item;
          const targetRoute = target.getAttribute("href") ?? _value;

          setState("updatePage", targetPath);

          self.screens(targetPath, targetRoute, globalState);
          self.elem("nav")?.classList.add("mobile__menu");

          window.scroll({
            top: 0,
          });

          break;
        case "menu":
          self.elem("nav")?.classList.remove("mobile__menu");

          break;
        case "close_menu":
          self.elem("nav")?.classList.add("mobile__menu");

          break;
        default:
          break;
      }
    }
    handleChange(self) {
      switch (_id) {
        default:
          break;
      }
    }
    handleSubmit(self) {}
    handleInputs(self) {
      let searchResults;
      switch (_id) {
        default:
          break;
      }
    }
    handleHover(self) {
      switch (_id) {
        case "toggle_link_list":
          if (
            globalState.currentState?.openedLink ===
            `chevron_${_value?.toLowerCase()}`
          ) {
            return;
          } else {
            if (globalState.currentState?.openedLink) {
              self
                .elem(globalState.currentState?.openedLink)
                ?.classList.toggle("rotate-open");

              self
                .elem(
                  `${
                    globalState.currentState?.openedLink?.split("_")?.[1]
                  }_links`
                )
                ?.classList.add("hide-nav");

              setState("updateCurrentState", {
                prop: "openedLink",
                value: `chevron_${_value?.toLowerCase()}`,
              });
            }
          }

          self
            .elem(`${_value?.toLowerCase()}_links`)
            ?.classList.remove("hide-nav");

          self
            .elem(`chevron_${_value?.toLowerCase()}`)
            ?.classList.add("rotate-open");

          setState("updateCurrentState", {
            prop: "openedLink",
            value: `chevron_${_value?.toLowerCase()}`,
          });

          break;
        case "nav_filter":
          // GET CURRENT OPENED LINK
          let openedList = globalState.currentState?.openedLink?.split("_")[1];

          self.elem(`${openedList}_links`)?.classList.add("hide-nav");

          self
            .elem(globalState.currentState?.openedLink)
            .classList.toggle("rotate-open");

          setState("updateCurrentState", {
            prop: "openedLink",
            value: ``,
          });

          break;

        default:
          break;
      }

      // console.log(this.target);
    }
  }

  const {handleClick, handleChange, handleSubmit, handleInputs, handleHover} =
    new _ev_();

  return {
    handleClick,
    handleChange,
    handleSubmit,
    handleInputs,
    handleHover,
  };
}
