import View from "../../view/View.js";
import {globalState, setState} from "../../model/model.js";

const Header = (function () {
  const state = {
    links: [
      {
        name: "Shipping",
        link: "/shipping",
        active: false,
      },
      {
        name: "Confirm Details",
        link: "/confirm_details",
        active: false,
      },
      {
        name: "Payment",
        link: "/payments",
        active: false,
      },
    ],
  };
  const view = new View();

  const init = (props) => {
    view.render("", mockup(props));
  };
  const checkout = (props) => {
    return `
       <div class="logo-header">
                  <a id="logo" href="/">
                    <img
                      loading="eager"
                      src="./images/Mat__Blaze.png"
                      alt="mat__blaze"
                      style="width: 120px"
                    />
                    <!-- <h2 id="logo_name" class="logo-title">afripavostudio</h2> -->
                  </a>
                  <div class="link-tree">
                    ${state.links
                      .map((item) => {
                        console.log(window.location.pathname);
                        if (window.location.pathname === item?.link) {
                          item.active = true;
                        }

                        if (item?.active) {
                          return `
                                     <div class="link-tree-item">
                      <a style="color: ${
                        window.location.pathname === item?.link ? "blue" : ""
                      }" data-id="link"  class="active-crumb" href="${
                            item?.link
                          }">${item?.name}</a>
                      <ion-icon
                        class="selected-crumb"
                        name="chevron-forward-outline"
                      ></ion-icon>
                    </div>
                                  `;
                        }

                        return `
                                 <div class="link-tree-item">
                      <button>${item?.name}</button>
                      <ion-icon
                       
                        name="chevron-forward-outline"
                      ></ion-icon>
                    </div>
                              `;
                      })
                      .join(``)}
                  </div>
                </div>
    `;
  };
  return {
    checkout,
  };
})();

export default Header;
