import View from "../view/View.js";

class Shop extends View {
  constructor(props) {
    super(props);
  }

  init(props) {
    this.render("app", this.mockup());

    this.isDisplayed("herbalifepopup", "hide-it");
  }

  mockup(props) {
    return `
  
	
      <section id="shop">
        <div id="filters">
        <button class="is-mobile" data-id="filters_slide"><ion-icon data-id="filters_slide" class="icon-md" name="close-outline"></ion-icon></button>
          <div class="sort__header">
            <div class="sort_">
            <h4 style="margin: 0">SORT</h4>
            <select data-id="sort_select" id="sort_select">
              <option value="default">Default</option>
              <option value="price-low">Price: Low to High</option>
              <option value="price-high">Price: High to Low</option>
              <option value="featured">Featured</option>
            </select>
            </div>
            
          </div>
          <div id="filter_container" class="filter__container"></div>
        </div>
        <div id="shop_front">
          <div class="header">
            <button class="is-desktop" data-id="filters_open"><ion-icon data-id="filters_open" name="filter-outline"></ion-icon><span data-id="filters_open" class="filter__btn">filters</span></button>
            <button class="is-mobile" data-id="filters_slide"><ion-icon data-id="filters_slide" name="filter-outline"></ion-icon><span data-id="filters_slide" class="filter__btn">filters</span></button>
          </div>
          <div id="product_list" class="body"></div>
          <div class="footer">
            <div id="pagination_el"></div>
          </div>
        </div>
      </section>

      	
    `;
  }
}
export default Shop;
