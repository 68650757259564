import Footer from "../components/footer/Footer.js";
import Header from "../components/header/Header.js";
import Totals from "../components/shop/totals/Totals.js";
import {globalState} from "../model/model.js";
import View from "../view/View.js";

class ConfirmDetails extends View {
  constructor(props) {
    super(props);
  }

  init(props) {
    // document.body.innerHTML = "";
    this.render(
      "app",
      this.mockup(
        JSON.parse(localStorage.getItem(globalState.shop.shippingId)) ?? {}
      )
    );
    // document.body.insertAdjacentHTML(
    //   "afterbegin",
    //   this.mockup(
    //     JSON.parse(localStorage.getItem(globalState.shop.shippingId)) ?? {}
    //   )
    // );
  }

  mockup(props) {
    return `
      <main>
      <div id="app" style="margin-top: 30px !important">
        <!-- CONTENT -->
        <div class="product-wrapper">
          <div class="products">
            <div class="cart-items-header">
              <button data-id="open_order_summary" class="show-summary-btn">
                <p data-id="open_order_summary">Order summary</p>
                <ion-icon
                 id="order_chevron"
                  data-id="open_order_summary"
                  class="icon-md"
                  name="chevron-down-outline"
                ></ion-icon>
              </button>
              <p id="summary_total_mobile">R${
                Totals.calculateTotal().subtotal
              }</p>
            </div>
            <div class="container">
              <div class="left-container center-it" style="margin-top: 0px !important">
                <div id="checkout_header" class="checkout-header" style="padding-top: 0px !important">
                   ${Header.checkout()}
                </div>
                <div class="checkout-information">
                  <div class="shipping-info">
                    <div class="cart-summary-item">
                      <p>Contact</p>
                      <p id="shipping_address_email">
                        ${props?.email}
                      </p>
                      <a data-id="link" href="/shipping" class="info-link">change</a>
                    </div>
                    <div class="cart-summary-item">
                      <p>Ship to</p>
                      <p id="shipping_address_address">
                        ${props?.address}
                      </p>
                      <a data-id="link" href="/shipping" class="info-link">change</a>
                    </div>
                  </div>
                  <div class="product-id shipping-note">
                    <h2>Shipping</h2>
                    <p>
                      Shipping can take up to 5 -7 working days.<a data-id="link" href="/contactus">contact us</a>
                    </p>
                  </div>
                  <div class="checkout-getaway payment-checkout-getaway">
                    <div class="payment-supported">
                      <a data-id="link" href="/shipping" class="btn-md light-btn info-link">
                        <ion-icon data-id="link" data-value="/shipping" data-item="/shipping"  name="arrow-back-outline"></ion-icon>
                        <span data-id="link"  data-value="/shipping" data-item="/shipping" style="margin-left: 10px">
                          Return to Information
                        </span>
                      </a>
                    </div>
                    <div class="checkout-container">
                      <a data-id="link" href="/payments" class="btn-md">Continue to Payment</a>
                    </div>
                  </div>
                </div>
              </div>
                 <div
                id="order_summary"
                class="right-container right-checkout-details translate-Y"
              >
                <div id="cart_items" class="cart-items"></div>
                <div class="line-divider-dark"></div>
                <div class="gift-card"></div>
                <!-- <div class="line-divider-dark"></div> -->
               <div class="total-summary" id="summary_container">
                  
               </div>
            </div>
            </div>
          </div>
        </div>

        <!-- CONTENT -->
      </div>
    </main>

    ${Footer.checkout()}
      `;
  }
}
export default ConfirmDetails;
