import {globalState, updateTutorials} from "../../model/Model.js";
import {getAuthApi} from "../../model/api/auth.js";
import View from "../../view/View.js";

const BlogCards = (function () {
  // state

  // methods
  const init = async (props) => {
    new View().render("blog_cards", mockup(globalState.tutorials.list));
  };

  // view
  const mockup = (props) => {
    return cards(props);
  };

  const cards = (props) => {
    return `${props
      ?.map((item) => {
        return card(item);
      })
      .join(``)}`;
  };

  const card = (item) => {
    return `
    <div class="blog-post">
        <div class="header content-is-loading">
                <img data-src="${item?.imgCover}" alt="" class="img-cover">
              </div>
              <div class="body">
                <h2>${item?.title}</h2>
               <p>${item?.description}</p>
              </div>
            <div class="footer">
              <a data-id="link" href="/blog?post=${item?.title
                ?.split(" ")
                ?.join("-")
                .toLowerCase()}&id=${item?._id}" data-value="id=${
      item?._id
    }">Read More</a>
            </div>
            </div>
    `;
  };

  return {
    init,
  };
})();

export default BlogCards;
