function lazyLoad(elementSelector, callback) {
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  };

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const element = entry.target;
        callback(element);
        observer.unobserve(element);
      }
    });
  }, options);

  const elements = document.querySelectorAll(elementSelector);

  console.log(elements);
  elements.forEach((element) => {
    observer.observe(element);
  });
}

export {lazyLoad};
