import _globalShop from "./_globalShop";

class Product {
  constructor(props) {}

  // init(props) {
  //   this.render("product_list", this.mockup(props));
  // }

  mockup(props) {
    if (props?.length === 0) {
      return this.notification(
        "No Products Found! <br/><br/> <p>You can always adjust products displayed by sorting and filtering.</p>"
      );
    }
    return props?.map((item) => this.product(item)).join(``);
  }

  product({id, images, title, flavor, sizes, price}) {
    return `<div id="${id}" class="product">
    <div class="product-card">
  <a href="/product?id=${id}" class="product-image content-is-loading">
    <img data-id="link" data-item="/product" data-value="/product?id=${id}" loading="lazy" src="${
      images[0]
    }" alt="Product Image" class="img">
  </a>
  <div class="product-info">
    <h2 class="title">${title}</h2>
    <p class="price">R${price}</p>

    

    <div class="size-selection">
      <label for="size">Select Size</label>
      <select id="size">
        ${sizes
          ?.map((item) => `<option value="${item}">${item}</option>`)
          .join(``)}
      </select>
    </div>
       ${_globalShop.addToCart(id, true)}     
  </div>
</div>
</div>`;
  }

  notification(msg) {
    return `<div class="notification__products"><h3 >${msg}</h3></div>`;
  }
}
export default Product;
