import Accordion from "../../accordion/Accordion.js";
import View from "../../../view/View.js";
import {globalState, setState} from "../../../model/model.js";
import CartIcon from "../cart/CartIcon.js";
import CartNotification from "../cart/notification/CartNotification.js";
import _globalShop from "../_globalShop.js";
import Quantity from "../cart/quantity/Quantity.js";

const ProductDetails = (function () {
  // State
  const state = {
    products: globalState.shop.products.slice(),
    cart: JSON.parse(globalState.shop.cart.slice()),
    quantity: 1,
    cartId: globalState.shop.cartId,
    productId() {
      let _id = window.location?.search?.split("=")?.[1];

      if (!_id) {
        throw Error(
          "No Product Matches this Id in our database, please go back to the shop page and selected the product you want to add to your cart."
        );
      }

      return _id;
    },
  };
  // Methods
  const init = (props) => {
    if (state.cart?.length > 0) {
      let cartItem = filterProduct(state.cart);
    }
    return mockup(props);
  };

  function handleClick(e) {
    const target = e.target;
    const id = e.target.id;
    const value = e.target.value;
    const _id = target.dataset.id;
    const _value = target.dataset.value;

    const view = new View();

    switch (_id) {
      case "add_to_carts":
        let tempCart = state.cart?.slice();
        // Prepare the cart item
        let cartItem = filterProduct(state.products).map(
          ({id, images, title, price, short_description, size}) => {
            return {
              id: id,
              image: images?.[0],
              title: title,
              description: short_description,
              price: price,
              size: size,
              quantity: state.quantity,
            };
          }
        );

        //Check if product in the cart
        if (
          tempCart?.some(({id}) => {
            return state.productId() === id;
          })
        ) {
          // Change item quantity
          cartItem = filterProduct(tempCart)[0];
          cartItem.quantity = state.quantity;

          tempCart.push(cartItem);
          // Update the UI => quantity has been changed
        } else {
          // Push in item
          tempCart.push(cartItem?.[0]);
        }

        console.log(tempCart);

        saveToLocal(tempCart);
        CartIcon.init();

        break;
    }
  }

  function saveToLocal(cart) {
    // Saves to local cart

    console.log(cart);
    localStorage.setItem(state.cartId, JSON.stringify(cart));
    setState("addToCart", cart);

    // Once saved change the cart icons number and color

    // Show Cart Notification
    CartNotification.init(cart[0]);
  }

  function filterProduct(products) {
    return products?.filter((el) => el?.id === state.productId());
  }

  // Views

  function mockup(props) {
    let product = filterProduct(props)[0];

    return ` <div id="pd__">
    <div class="left__item">
    ${images(product)}</div>
    <div class="right__item">${details(product)}</div>
  </div>`;
  }

  function images({images}) {
    return ` <div class="pd__active-product">
        <img
          src="${images[0]}"
          alt="product image"
          class="img"
        />

        <button class="hover-btns hide-it" id="prev_product">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </button>
        <button class="hover-btns hide-it" id="next_product">
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </button>
      </div>`;
  }

  function review() {
    return `
      <div class="review-stamp hide-it">
                    <div class="review-icons">
                      <ion-icon
                        class="star-icon icon-sm"
                        name="star-outline"
                      ></ion-icon>
                      <ion-icon
                        class="star-icon icon-sm"
                        name="star-outline"
                      ></ion-icon>
                      <ion-icon
                        class="star-icon icon-sm"
                        name="star-outline"
                      ></ion-icon>
                      <ion-icon
                        class="star-icon icon-sm"
                        name="star-outline"
                      ></ion-icon>
                      <ion-icon
                        class="icon-sm"
                        name="star-half-outline"
                      ></ion-icon>
                    </div>
                    <a href="#">(666)</a>
                    <p>Write a review</p>
                  </div>
    `;
  }

  function details({
    flavor,
    id,
    quantity,
    short_description,
    sizes,
    title,
    description,
    accordion,
    price,
  }) {
    return `
      <div id="product_details" class="product-details">
                  
                  <div class="product-id">
                    <h2>${title}</h2>
                    <p>
                    ${short_description}
                    </p>
                    <h2 style="font-size: 18pt">R${price}</h2>
                    <p>Flavor: <strong>${flavor}</strong></p>
                    <p>Size: <strong>${sizes}</strong></p>
                  </div>
                  <div class="quantity__container">
                   <div class="counter-container">
                     ${Quantity.mockup(id, quantity)}
                     </div>
                    ${_globalShop.addToCart(id)}
                     </div>
                  <div class="product-description">
                      ${accodtions(accordion[0])}
                   
                  </div>
                
                    </div>
    `;
  }

  function addToCart(id) {
    return `<div data-id="add_to_cart" class="add-to-cart">
      <button
        id="product_add_to_cart"
        data-id="add_to_cart"
        data-value="${id}"
        class="add-bag-btn"
      >
        ADD TO BAG
      </button>
    </div>`;
  }

  function accodtions({title, children, _id}) {
    // console.log(data);
    return `
           ${Accordion.accordion_v1(
             title,
             children
               ?.map(({type, content}) => {
                 if (type === "para") {
                   return `<p>${content}</p>`;
                 } else if (type === "list") {
                   return `<ul>${content
                     ?.map((item) => `<li class="bullet-point">${item}</li>`)
                     .join(``)}</ul>`;
                 }
               })
               .join(``),
             "pd_full__accordion",
             "pd_b__bottom"
           )}
               `;
  }

  return {
    init,
    handleClick,
  };
})();

window.addEventListener("click", _globalShop.handleClick);
export default ProductDetails;
