import View from "../../view/View.js";
import {globalState, setState} from "../../model/model.js";

const Policies = (function () {
  const state = {
    private: globalState.policies.private,
  };
  const view = new View();

  const init = (props) => {
    view.render("app", mockup());
    // view.elem("app").appendChild(mockup());
  };

  const mockup = (props) => {
    let htmlMock;
    if (window.location.pathname === "/privatepolicy") {
      htmlMock = createPolicyPage(state.private.title, state.private.content);
    }

    return `<div class="policy__container">${htmlMock}</div>`;
  };

  function createPolicyPage(title, content) {
    return `
    
      <h1>${title}</h1>
      ${content}
    `;
  }

  return {
    init,
  };
})();

export default Policies;
