import View from "../view/View.js";

class BusinessOpportunity extends View {
  constructor(props) {
    super(props);
  }

  init(props) {
    this.render("app", this.mockup());
  }

  mockup(props) {
    return ` <section id="business_opportunity"></section>`;
  }
}
export default BusinessOpportunity;
