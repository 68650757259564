import {globalState} from "../../../model/Model";
import {postApi} from "../model/api/api";

export class _ev {
  constructor() {}

  handleInputs(e) {
    const target = e.target;
    const id = e.target.id;
    const value = e.target.value;
    const _id = target.dataset.id;
    const _value = target.dataset.value;
    const _item = target.dataset.item;

    switch (_id) {
      case "login_inputs":
        // console.log(state);
        console.log(value);
        break;
      case "register_inputs":
        console.log(value);
        break;

      default:
        break;
    }
  }
  async handleSubmit(e) {
    e.preventDefault();

    const target = e.target;
    const id = e.target.id;
    const value = e.target.value;
    const _id = target.dataset.id;
    const _value = target.dataset.value;
    const _item = target.dataset.item;
    // Function to validate email format
    function validateEmail(email) {
      const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return emailRegex.test(email);
    }

    // Function to check password strength
    function checkPasswordStrength(password) {
      // Implement your password strength criteria
      // For example:
      // Minimum length: 8 characters
      // At least one uppercase letter, one lowercase letter, one number, and one special character
      const passwordRegex =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
      return passwordRegex.test(password);
    }

    // Function to display error message
    function showError(message) {
      const errorElement = document.querySelector("#errorMessage");
      errorElement.textContent = message;
      errorElement.style.display = "block";
    }
    const username = document.getElementById("username")?.value?.trim();
    const email = document.getElementById("email")?.value?.trim();
    const password = document.getElementById("password")?.value?.trim();
    const companyName = document.getElementById("companyName")?.value?.trim();
    const businessType = document.getElementById("businessType")?.value?.trim();
    const phoneNumber = document.getElementById("phoneNumber")?.value?.trim();

    switch (_id) {
      case "login":
        // Call the api
        const results = await postApi("auth/login", {
          email,
          password,
        });

        if (results.status === "success") {
          console.log(globalState?.tokenID);
          localStorage.setItem(globalState?.tokenID, results?.data?.token);
          window.location.href = "/accounts";
        }

        break;
      case "registration":
        // Call the api
        const registration = await postApi(
          "auth/registration/serviceprovider",
          {
            username,
            email,
            password,
            companyName,
            businessType,
            phoneNumber,
          }
        );

        if (registration.status === "success") {
          localStorage.setItem(globalState.tokenID, registration?.data?.token);
          window.location.href = "/login";
        }
        break;

      default:
        break;
    }
  }
}
