import View from "../../../view/View.js";
import {globalState, setState} from "../../../model/model.js";

const Totals = (function () {
  const state = {
    subtotal: 0,
    _shipping: 120,
    get shipping() {
      if (this.subtotal >= 750) {
        this._shipping = 0;
        return "FREE SHIPPING";
      }

      this._shipping = 120;
      return `R120`;
    },
    total: 0,
    cart() {
      return JSON.parse(localStorage.getItem(globalState.shop.cartId));
    },
  };

  function calculateSubtotal() {
    let cartItems = state.cart();
    let subtotal = 0;
    for (let i = 0; i < cartItems.length; i++) {
      subtotal += cartItems[i]
        ? cartItems[i].price
        : 0 * cartItems
        ? cartItems[i].quantity
        : 0;
    }
    state.subtotal = subtotal;
    return state.subtotal;
  }

  function calculateTotal() {
    const taxRate = 0; // 10% tax rate
    const shippingCost = state._shipping; // $10 flat shipping cost
    calculateSubtotal();
    const taxAmount = state.subtotal * taxRate;
    const total = state.subtotal + taxAmount + shippingCost;

    state.total = total;

    return {
      subtotal: state.subtotal,
      total: state.total,
    };
  }

  const view = new View();

  const render = (props) => {
    calculateTotal();
    view.render("summary_container", mockup(props));
  };

  const mockup = (props) => {
    return `
                  <div class="total-item">
                    <p>Subtotal</p>
                    <h3 id="summary_subTotal">R${state.subtotal}</h3>
                  </div>
                  <div class="total-item">
                    <p>Shipping</p>
                    <h3 id="summary_shipping">${state.shipping}</h3>
                  </div>
                </div>
                <div class="line-divider-dark"></div>
                <div id="total__item" class="total-summary">
                  <div class="total-item">
                    <p><strong>Total</strong></p>
                    <h3 id="summary_total"><strong style="font-size: 16pt">R${state.total}</strong></h3>
                  </div>
                </div>
        `;
  };

  return {
    render,
    calculateTotal,
  };
})();

export default Totals;
