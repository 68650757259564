import Accordion from "../components/accordion/Accordion.js";
import ProductDetails from "../components/shop/details/ProductDetails.js";
import View from "../view/View.js";

class Details extends View {
  constructor(props) {
    super(props);
  }

  init(props) {
    this.render("app", this.mockup(props));
  }

  mockup(props) {
    return `
       
        <div class="product-wrapper">
          ${ProductDetails.init(props)}
        </div>
    `;
  }
}
export default Details;
