import View from "../view/View.js";
import BlogPosts from "./BlogPost.js";
import "./Blog.scss";

class Blog extends View {
  constructor(props) {
    super(props);
  }

  init(props) {
    this.render("app", this.mockup(props));
    this.elem("splash_screen").classList.add("hide-it");
  }

  mockup(props) {
    return `<div id="blog__main">
    <div id="blog_post_container" class="left">${props}</div>
        <div id="blog_sidebar" class="right"></div>
      </div>`;
  }
}
export default Blog;
