export default class Pagination {
  init(page, productsPerPage, products) {
    const totalPages = Math.ceil(products.length / productsPerPage);
    // console.log("AGAIN");
    this.displayPageButtons(totalPages, page);
    return this.paginate(page, productsPerPage, products);
  }
  paginate(page, productsPerPage, products) {
    const startIndex = (page - 1) * productsPerPage;
    const endIndex = startIndex + productsPerPage;
    const paginatedProducts = products.slice(startIndex, endIndex);

    return paginatedProducts;
  }

  displayPageButtons(totalPages, page) {
    const paginationEl = document.getElementById("pagination_el");

    if (totalPages === 1) {
      return;
    }
    for (let i = 1; i <= totalPages; i++) {
      const button = document.createElement("button");
      const info = document.createElement("i");
      info.textContent = ".";
      button.classList.add("paginate__btns");
      info.classList.add("paginate__dots");
      button.textContent = i;
      button.dataset.id = "next_page";

      if (i <= 4) {
        paginationEl.appendChild(button);
      } else if (i === totalPages) {
        paginationEl.appendChild(button);
      } else if (i > 4 && i <= 6) {
        paginationEl.appendChild(info);
      }
    }
  }
}
