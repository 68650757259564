import View from "../../view/View.js";
import "./QuizCard.css";

const QuizCard = (function () {
  const state = {
    dataList: [
      {
        id: 1,
        icon: "🏋️‍♀️",
        title: "Weight Loss",
        link: "https://form.jotform.com/231453598977575",
      },
      {
        id: 2,
        icon: "🏋️‍♂️",
        title: "Weight Gain",
        link: "https://form.jotform.com/231385290189563",
      },
      {
        id: 3,
        icon: "🏃‍♀️",
        title: "Improve Sports Performance",
        link: "https://form.jotform.com/231395532765563",
      },
    ],
  };
  const view = new View();

  const init = (props) => {
    view.render("quiz__modal_cards", "");
    view.render("quiz__modal_cards", mockup(props));
  };
  const mockup = (props) => {
    return state?.dataList
      ?.map(({icon, id, title, link}) => {
        return `<a target="_blank" href="${link}" class="quiz__modal-card">
        <p>${icon}</p>
        <h3>${title}</h3>
      </a>`;
      })
      .join(``);
  };
  return {
    init,
  };
})();

export default QuizCard;
