import _views from "./views/_views";
import "./css/_style.css";

const ServiceCard = (function () {
  const view = new _views();

  const init = async (props) => {
    view.render("service_cards", mockup(props));
  };
  const mockup = (props) => {
    return view.posts(props);
  };

  return {
    init,
  };
})();

export default ServiceCard;

// const ServiceCard = (function () {
//   const state = {
//     // serviceList: {
//     //   "@context": "https://schema.org",
//     //   "@type": "ItemList",
//     //   itemListElement: [
//     //     {
//     //       "@context": "https://schema.org",
//     //       "@type": "Service",
//     //       serviceType: "Mommy Transformation Challenge",
//     //       provider: {
//     //         "@type": "Organization",
//     //         name: "wellnesswithbridgette",
//     //         logo: {
//     //           "@type": "ImageObject",
//     //           url: "URL_OF_YOUR_BUSINESS_LOGO",
//     //         },
//     //       },
//     //       description:
//     //         "Are you ready to reclaim your pre-baby body, boost your energy levels, and feel confident in your own skin again? Our Mommy Transformation Challenge is specifically designed to help moms like you achieve their health and fitness goals.",
//     //       video: "https://www.youtube.com/embed/FKGO9180lHE",
//     //       url: "https://form.jotform.com/231673160585559",
//     //     },
//     //     {
//     //       "@context": "https://schema.org",
//     //       "@type": "Service",
//     //       serviceType: "Belly Fat Challenge",
//     //       provider: {
//     //         "@type": "Organization",
//     //         name: "wellnesswithbridgette",
//     //         logo: {
//     //           "@type": "ImageObject",
//     //           url: "URL_OF_YOUR_BUSINESS_LOGO",
//     //         },
//     //       },
//     //       description:
//     //         "Say goodbye to stubborn belly fat and hello to a toned and sculpted midsection. Our Belly Fat Challenge combines effective workouts, targeted nutrition plans, and guidance to help you shed those extra kilos around your waist. Achieve a flatter and more defined tummy.",
//     //       url: "URL_OF_THE_BELLY_FAT_CHALLENGE_PAGE",
//     //     },
//     //     {
//     //       "@context": "https://schema.org",
//     //       "@type": "Service",
//     //       serviceType: "Gut Health",
//     //       provider: {
//     //         "@type": "Organization",
//     //         name: "wellnesswithbridgette",
//     //         logo: {
//     //           "@type": "ImageObject",
//     //           url: "URL_OF_YOUR_BUSINESS_LOGO",
//     //         },
//     //       },
//     //       description:
//     //         "Maintaining a healthy gut is essential for overall well-being and vitality. Our Gut Health program focuses on optimizing your digestive system through nutrition, lifestyle adjustments, and targeted supplementation. Improve gut health, reduce bloating and discomfort, and enhance nutrient absorption.",
//     //       url: "https://form.jotform.com/231673160585559",
//     //     },
//     //     {
//     //       "@context": "https://schema.org",
//     //       "@type": "Service",
//     //       serviceType: "Immune Boosting",
//     //       provider: {
//     //         "@type": "Organization",
//     //         name: "wellnesswithbridgette",
//     //         logo: {
//     //           "@type": "ImageObject",
//     //           url: "URL_OF_YOUR_BUSINESS_LOGO",
//     //         },
//     //       },
//     //       description:
//     //         "Our Immune Boosting program is designed to support your immune health through nutrition, lifestyle practices, and natural remedies. Strengthen your body's defense mechanisms and protect against common illnesses.",
//     //       url: "https://form.jotform.com/231673160585559",
//     //     },
//     //     {
//     //       "@context": "https://schema.org",
//     //       "@type": "Service",
//     //       serviceType: "Anti-Aging",
//     //       provider: {
//     //         "@type": "Organization",
//     //         name: "wellnesswithbridgette",
//     //         logo: {
//     //           "@type": "ImageObject",
//     //           url: "URL_OF_YOUR_BUSINESS_LOGO",
//     //         },
//     //       },
//     //       description:
//     //         "Our Anti-Aging program combines nutrition, skincare tips, stress management techniques, and lifestyle adjustments to help you embrace the aging process with confidence. Unlock the secrets to healthy aging and enhance your natural beauty from within.",
//     //       url: "https://form.jotform.com/231673160585559",
//     //     },
//     //     {
//     //       "@context": "https://schema.org",
//     //       "@type": "Service",
//     //       serviceType: "21-Day Body Transformation Challenge",
//     //       provider: {
//     //         "@type": "Organization",
//     //         name: "wellnesswithbridgette",
//     //         logo: {
//     //           "@type": "ImageObject",
//     //           url: "URL_OF_YOUR_BUSINESS_LOGO",
//     //         },
//     //       },
//     //       description:
//     //         "If you're looking for a jumpstart to your wellness journey, our 21-Day Body Transformation Challenge is the perfect opportunity. This comprehensive program encompasses nutrition guidance, effective workouts, mindset training, and ongoing support to help you achieve significant results in a short period.",
//     //       url: "https://form.jotform.com/231673160585559",
//     //     },
//     //     {
//     //       "@context": "https://schema.org",
//     //       "@type": "Service",
//     //       serviceType: "Mindset Coaching for Personal Development",
//     //       provider: {
//     //         "@type": "Organization",
//     //         name: "wellnesswithbridgette",
//     //         logo: {
//     //           "@type": "ImageObject",
//     //           url: "URL_OF_YOUR_BUSINESS_LOGO",
//     //         },
//     //       },
//     //       description:
//     //         "Unlock your true potential and cultivate a positive mindset with our transformative mindset coaching. Through empowerment coaching, goal setting and achievement strategies, and resilience training, build self-belief, inner strength, and confidence.",
//     //       url: "https://form.jotform.com/231673160585559",
//     //     },
//     //   ],
//     // },
//   };

//   // Methods

//   return state.serviceList?.itemListElement
//     .map(
//       (service) => `<div id="${service?.serviceType}" class="service__card">
//       <div class="header content-is-loading">
//       <img data-src="./images/${
//         service?.serviceType
//       }.jpg" class="img-cover" alt="wellnesswithbridgette ${
//         service?.serviceType
//       }" />

//       </div>
//       <div class="body">
//         <h3>${service?.serviceType}</h3>
//         <p>
//          ${service?.description}
//         </p>
//       </div>
//       <div class="footer">
//         <a href="${
//           service?.url?.startsWith("https") ? service?.url : "#"
//         }" class="learn-more" target="_blank">
//           Learn More
//         </a>
//       </div>
//     </div>`
//     )
//     .join(``);
// })();

// export {ServiceCard};
