import View from "../../view/View.js";
import {globalState, setState} from "../../model/model.js";

const Footer = (function () {
  const view = new View();

  const checkout = (props) => {
    return `
    <footer>
      <div id="footer-policy">
        <p>&copy; 2023 Afripavostudio, All Rights Reserved</p>
        <div class="policies">
         <a class="policy-link" data-id="link" href="/privatepolicy">Privacy Policy</a>
         
        </div>
      </div>
    </footer>
        `;
  };
  return {
    checkout,
  };
})();

export default Footer;
