import View from "../view/View.js";

class Home extends View {
  constructor(props) {
    super(props);
  }

  init(props) {
    this.render("app", this.mockup());
  }

  mockup(props) {
    return `<section id="hero">
        <div class="hero__img content-is-loading">
           <img  data-src="./images/profile_1.jpg" alt="bridgette lekhuleni" class="img-cover">
        </div>
        <div class="hero__details">
          <h1>Coaching for Moms</h1>
          <p>Achieve Your Ideal Weight and Embrace Mindful Living with Personalized Support</p>
        </div>
        </section>

        <section id="intro">
          
          <div class="indent"></div>

          <div class="intro-body">
         
            <p>Welcome to WellnesswithBridgette, the ultimate destination for achieving your health, wellness, and mindset goals.</p>

          </div>
        </section>

     

        <section id="about_me">
          <div class="container-flex">
            <div class="about-me-img content-is-loading">
             
              <iframe id="about__me-frame" loading="eager" src="https://www.youtube.com/embed/v6O7ru5oC9o" title="About me" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div id="about_bridgette_lekhuleni" class="about-me-details">
              <h1>Hi, I'm <a href="#about_bridgette_lekhuleni">Bridgette</a></h1>
              <p style="margin-bottom: 25px;">Entrepreneur | Holistic Wellness Coach | Professional Network Marketer</p>
              <p>As an independent Herbalife distributor and wellness coach since 2013, I am passionate about helping moms like you transform their lives through a holistic approach. Explore our comprehensive range of services and discover how I can empower you to become the best version of yourself.</p>
            </div>
          </div>
        </section>
      
        <section id="services">
        <h1>Knowledge Hub</h1>
              <p>Our blog is a content hub for all things health and wellness and it’s open to everyone!</p>
        <div id="service_cards" class="service__cards"></div>
      </section>

        <section id="blogs" class="hide-it">
          <div class="blog-post-container ">
            <div class="blog-post-header">
              <h1>Knowledge Hub</h1>
              <p>Our blog is a content hub for all things health and wellness and it’s open to everyone!</p>
            </div>
            <div id="blog_cards" class="blog-post-body">
          
             
            </div>
          </div>
        </section>

        <section id="newsletter" class="hide-it">
          <div class="blog-post-container newsletter">
            <div class="blog-post-header newsletter-header">
              <h1>Stay In The Know.</h1>
              <p>We have a weekly newsletter, on different topics. </p>
              <div>


              </div>
              <p class="hide-it">To signup, just select the topic you are interested in and we will share some useful tips and information!</p>
            </div>
            <form id="newsletter_form" class="hide-it">
                  <div class="input-container">
                      <input
                        data-id="inputs"
                        type="email"
                        id="email"
                        required
                        class="inputs"
                        placeholder="Email"
                      />
                    </div>
                      <div class="checkout-container">
                        <button
                          data-id="submit_shipping"
                          type="submit"
                          class="btn-md"
                        >
                          Sign me Up
                        </button>
                      </div>
            </form>
          </div>
        </section>`;
  }
}
export default Home;
