// import {globalState, setState} from "../../../model/model.js";

// import {globalState, updateCredits} from "../../model/Model";
// import {authApi, getAuthApi, postAuthApi} from "../../model/api/auth";
import "./landingPages.scss";
import {_ev} from "./events/credit_ev";

import landingPagesViews from "./views/_views";

const LandingPages = (function () {
  const view = new landingPagesViews();

  const handlers = new _ev();

  const init = async () => {
    view.render("app", view.mockup());
    view.elem("splash_screen").classList.add("hide-it");
    // console.log(view.elem("call_to_action"));
  };

  return {
    init,
    handleEvents: handlers.handleEvents,
  };
})();

window.addEventListener("click", LandingPages.handleEvents);

export default LandingPages;
