import View from "../../../../view/View.js";
import _globalShop from "../../../shop/_globalShop.js";
import Quantity from "../quantity/Quantity.js";

class CartView extends View {
  constructor(props) {
    super(props);
  }

  mockup(props) {
    return this.cart(props);
  }

  cart(props) {
    console.log(props);
    return props
      ?.map((item) => {
        if (item) {
          return this.row(item);
        }
      })
      .join(``);
  }

  row(props) {
    return `<div id="cart_${props?.id}" class="table-row">
      ${this.col_1(props)}
      ${this.col_2(props)}
      ${this.col_3(props)}
    </div>`;
  }

  col_1({id, image, title, price, size}) {
    let alt = image?.split("/")[2]?.split(".")[0];
    return `
    <div class="cart-table-cell-main">
        <div class="img-thumbnail content-is-loading"> <img
              data-id="img_slider"
              data-value="${id}"
              data-src="${image}"
              alt="${alt}"
              class="img-cover"
            /></div>
        <div class="product-id">
          <h3>${title}</h3>
          <p><b>R${price}</b></p>
          <p>size: ${size}</p>
          <button data-id="delete_cart_item" data-value="${id}">
            <ion-icon  data-id="delete_cart_item" data-value="${id}" class="icon-md" name="trash-outline"></ion-icon>
          </button>
        </div>
    </div>  
      `;
  }

  col_2({id, quantity}) {
    return `<div class="cart-table-cell">
      <div class="counter-container">
        ${Quantity.mockup(id, quantity)}
      </div>
    </div>`;
  }

  col_3({id, price, quantity}) {
    return `<div class="cart-table-cell">
        <p id="cart_item_price_${id}">R${price * quantity}</p>
      </div>`;
  }

  total({subTotal, shipping}) {
    console.log(subTotal);
    return `<div class="cart-table-cell-main"></div>
        <div class="cart-table-cell"></div>
        <div class="cart-table-cell">
          <h2 id="cart_total">Total: R${subTotal}</h2>
        </div>`;
  }
}
export default CartView;
