// Functions to get products by size
export function filterProducts(
  products,
  {category, selectedSizes, selectedFlavors, selectedPriceRange, featured, sale}
) {
  return products.filter((product) => {
    // Match by category
    const isMatchingCategory = !category || product.category === category;

    // Match by size
    const isMatchingSize =
      selectedSizes?.length === 0 ||
      selectedSizes?.some((size) => product.sizes.includes(size));
    // Match by flavor
    const isMatchingFlavor =
      selectedFlavors?.length === 0 ||
      selectedFlavors?.some((flavor) => product.flavor.includes(flavor));

    // Match by price
    const isMatchingPrice =
      selectedPriceRange?.max === undefined ||
      (product.price >= selectedPriceRange?.min &&
        product.price <= selectedPriceRange?.max);
    // Match by featured
    const isMatchingFeatured = !featured || product.featured === true;
    // console.log(isMatchingFeatured);
    // Match by sale
    const isMatchingSale = !sale || product.sale === true;

    return (
      isMatchingCategory &&
      isMatchingSize &&
      isMatchingFlavor &&
      isMatchingPrice &&
      isMatchingFeatured &&
      isMatchingSale
    );
  });
}
