import Search from "../components/shop/Search.js";
import Sorting from "../components/shop/Sorting.js";
import {filterProducts} from "../components/shop/utils/filterProducts.js";
import {Controller} from "../controller/controller.js";
import {setState, globalState} from "../model/model.js";
import View from "../view/View.js";

export function shopEvents(e) {
  const target = e.target;
  const id = e.target.id;
  const value = e.target.value;
  const _id = target.dataset.id;
  const _value = target.dataset.value;

  // const {products, filter, selectedProduct} = globalState.shop;

  class _shop_ {
    constructor() {
      // super();
    }
    handleClick = (self) => {
      switch (_id) {
        case "pay_now":
          console.log(self.state);
          // view.isDisplayed("processing", "hide-it");
          // view.isDisplayed("modal_wrapper", "hide-it");

          // const total =
          //   this.state.cartTotal?.subTotal + this.state.cartTotal?.shipping;

          // const data = {
          //   amount: total * 100,
          //   currency: "ZAR",
          //   cancelUrl: "https://my-cancel-page.com",
          //   cart: this.state.cart,
          // };

          // const res = await fetch("http://localhost:8080/api/v1/payment", {
          //   method: "POST",
          //   headers: {"Content-Type": "application/json"},
          //   body: JSON.stringify(data),
          // });

          // const resData = await res.json();

          // if (resData?.status === "success") {
          //   const {data} = resData;

          //   window.location.href = data?.redirectUrl;
          // } else {
          //   view.elem("processing").classList.remove("hide-it");
          //   view.elem("modal_wrapper").classList.remove("hide-it");
          // }

          break;
        case "accordion":
          console.log(self);

          self.isDisplayed(`accordion_${_value}`, "hide-it");
          self.elem(`${_value}`).classList.toggle("rotate-open");
          break;
        case "next_page":
          // Update the page number

          setState("updateShopPage", target.textContent * 1);
          // display products

          self.views("shop", true);
          window.scroll({top: 20, behavior: "smooth"});

          break;

        case "filters_open":
          self.isDisplayed("filters", "hide-it");
          break;

        case "filters_slide":
          self.elem("filters").classList.remove("hide-it");
          self.elem("filters").classList.toggle("translate-left");
          break;

        case "search_hints":
          let searchResults = new Search().searching(
            _value,
            globalState.shop.products
          );

          setState("add", {prop: "searchValue", value: _value});
          setState("add", {prop: "searchResults", value: searchResults});

          self.views("search_result");

          break;
        case "mobile_search":
          self.elem("cancel_search_mobile").classList.remove("hide-it");
          self.elem("mobile_search").classList.remove("hide-it");
          self.elem("search_results").classList.remove("translate-up");

          self.elem("mobile_search_icon").classList.add("hide-it");

          self.views("search_result");
          break;
        case "open_search":
          console.log("HERE");
          self.elem("search_results").classList.add("translate-up");
          self.elem("cancel_search_desktop").classList.toggle("translate-up");

          // self.elem("cancel_search").classList.add("hide-it");
          self.elem("search_input").value = "";

          self.elem("cancel_search_desktop").classList.remove("hide-it");
          setState("add", {prop: "searchValue", value: ""});
          break;
        case "open_search_mobile":
          self.elem("cancel_search_desktop").classList.add("hide-it");
          self.elem("cancel_search_mobile").classList.add("hide-it");
          self.elem("mobile_search").classList.add("hide-it");
          self.elem("search_results").classList.add("translate-up");
          self.elem("mobile_search_icon").classList.remove("hide-it");
          //  self.views("search_result");
          self.elem("search_input").value = "";
          setState("add", {prop: "searchValue", value: ""});
          break;
        default:
          break;
      }
    };
    handleChange = (self) => {
      // console.log("CHANGE");
      switch (_id) {
        default:
          break;
      }
    };

    handleSubmit = (self) => {
      console.log(_id);
      switch (_id) {
        case "newsletter_form":
          console.log(globalState.newsletter);
          break;

        default:
          break;
      }
    };
    handleInputs = (self) => {
      console.log(_id);
      // console.log("INPUTS");
      let searchResults;
      switch (_id) {
        case "inputs_newsletter":
          let signupData = globalState.newsletter;

          for (let prop in signupData) {
            if (prop === id) {
              signupData[prop] = value;
            }
          }

          console.log(globalState.newsletter);
          console.log(signupData);

          break;
        case "search":
          self.elem("cancel_search_desktop").classList.remove("hide-it");
          self.elem("search_results").classList.remove("translate-up");

          searchResults = new Search().searching(
            value,
            globalState.shop.products
          );

          setState("add", {prop: "searchValue", value});
          setState("add", {prop: "searchResults", value: searchResults});

          self.views("search_result");

          break;
        case "filter_input":
          console.log("HERE");
          setState("updateFilter", {id, val: _value, value});

          let displayFiltered = filterProducts(
            globalState.shop.paginatedProducts,
            globalState.shop.filter
          );

          setState("updateFilteredProducts", displayFiltered);

          self.views("shop_filter");

          break;

        case "sort_select":
          let sortedFiltered;
          let sorter = new Sorting();
          if (globalState.shop.filteredProduct?.length > 0) {
            sortedFiltered = sorter.sorting(
              value,
              globalState.shop.filteredProduct
            );
          } else {
            sortedFiltered = sorter.sorting(
              value,
              globalState.shop.paginatedProducts
            );
          }

          // Do you added it to the filtered products or to the pagination Products
          // 1) filtered = create only when we filter, when no filtered product we return paginated products
          // 2) paginated products = created when we pagination, source: selected products
          // or selected products
          setState("updateFilteredProducts", sortedFiltered);

          self.views("shop_filter");
          break;

        case "newsletter_form":
          console.log(globalState.newsletter);
          break;

        default:
          break;
      }
    };
  }

  const {handleClick, handleChange, handleSubmit, handleInputs} = new _shop_();

  return {
    handleClick,
    handleChange,
    handleSubmit,
    handleInputs,
  };
}
