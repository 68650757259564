// import {globalState, updateWritingData} from "../../../model/model";

import {globalState, updateWritingData} from "../../../model/Model";
import {postAuthApi} from "../../../model/api/auth";
import View from "../../../view/View";
import CreateStory from "../CreateStory";
import _views from "../views/_views";

const view = new _views();

export class _ev extends View {
  async handleEvents(e) {
    const target = e.target;
    const id = e.target.id;
    const type = e.type;
    const value = e.target.value;
    const _id = target.dataset.id;
    const _value = target.dataset.value;
    const _item = target.dataset.item;

    let content = undefined;

    switch (_id) {
      case "save_content-prep":
        view.elem("tooltit_header").classList.remove("hide-it");

        view.render("writing_block-information", view.saveScreen());
        break;
      case "close_toolkit_header":
        view.elem("tooltit_header").classList.add("hide-it");
        break;
      case "save_content":
        const fileName = document.getElementById("post_name")?.value;

        content = CreateStory.quillLib.getContents();

        localStorage.setItem(fileName, JSON.stringify(content.ops));

        view.elem("tooltit_header").classList.add("hide-it");
        break;
      case "publish_content":
        for (let prop in globalState.writing.data) {
          console.log(!globalState.writing.data[prop]);
          if (!globalState.writing.data[prop]) {
            view.elem("tooltit_header").classList.remove("hide-it");
            return;
          }
        }
        view.elem("loading_res").classList.remove("hide-it");

        content = CreateStory.quillLib.getContents();

        updateWritingData({id: "content", value: content});

        const tokenID = localStorage.getItem(globalState?.tokenID);

        console.log(globalState.writing.data);
        console.log(tokenID);

        const result = await postAuthApi(
          "/stories/posts/create",
          tokenID,
          globalState.writing.data
        );

        if (result?.status === "success") {
          window.location.href = "/";
        }

        break;
      case "writing_inputs-img":
        const imgCover = view.elem("imgCover")?.files[0];

        if (!imgCover) {
          return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          console.log(base64String);
          // Send the base64String to your server
          // ...

          updateWritingData({id: "imgCover", value: base64String});

          view.elem("img_cover").src = base64String;
        };

        reader.readAsDataURL(imgCover);
        break;
      case "start_writing":
        e.preventDefault();
        const title = view.elem("title")?.value;
        const description = view.elem("description")?.value;

        updateWritingData({id: "title", value: title});
        globalState.writing.data.description = description;

        view.elem("tooltit_header").classList.add("hide-it");

        break;
      case "save_file_inputs":
        // const fileName = document.getElementById(id)?.value;

        // localStorage.setItem(fileName);
        break;

      default:
        break;
    }
    // console.log(_id);
  }
}
