import Footer from "../components/footer/Footer.js";
import Header from "../components/header/Header.js";
import Totals from "../components/shop/totals/Totals.js";
import View from "../view/View.js";

class Payments extends View {
  constructor(props) {
    super(props);
  }

  init(props) {
    document.body.innerHTML = "";
    document.body.insertAdjacentHTML("afterbegin", this.mockup(props));
  }

  mockup(props) {
    return `
     <!-- Splash Screen -->
    <div id="splash_screen" >
      <h1 style="font-family: cursive;">B</h1>
      <p >loading Content...</p>
    </div>
    <!-- MODAL -->
    <div id="modal_wrapper" class="hide-it">
      <div id="modal">
        <div id="modal_content">
          <!-- CONTENT -->
          <div id="processing" class="process-payment hide-it">
            <img
              data-src="./images/giphy.gif"
              alt="process payment"
              id="process_payment_img"
            />

            <p style="font-size: var(--font-16)">Setting up Payment...</p>
          </div>
          <div id="err" class="err-window hide-it">
            
              <ion-icon class="err-icon" name="close-circle-outline"></ion-icon>
              <p id="err_el">
                Sorry, We could not process you to Yoco payments, please check your connection
              </p>
            
            <button data-id="close_modal" class="btn">Cancel</button>
          </div>
          <!-- CONTENT -->
        </div>
        <button class="modal-filter"></button>
      </div>
    </div>
    

      <header >
      <a id="logo" data-id="link" class="logo-title" href="/">
       <img data-id="link" data-item="/" data-value="/" src="./images/Mat__Blaze.png" style="width: 25pt;" alt="wellnesswithbridgette" /> <span data-id="link" data-item="/" data-value="/">WellnessWithBridgette</span>
      </a>
      
    <div class="search__header is-desktop">
          <input
            data-id="search"
            type="search"
            id="search_input"
            placeholder="Search products"
          />
          <button id="cancel_search_desktop" class="hide-it cancel-search"  data-id="open_search">
            cancel
          </button>
        </div>
      <div class="nav-options">
        <div class="search-container hide-it">
          <!-- <button>
            <label for="search">
              <ion-icon class="seach-icon" name="search-outline"></ion-icon>
            </label>
          </button> -->
          <!-- <input
            data-id="search_val"
            id="search_val"
            class="search-input"
            type="search"
            placeholder="Search"
          /> -->
        </div>
         <div data-id="link" data-value="/cart" data-item="/cart" id="cart_icon" class="cart__icon is-desktop">

         </div>
      </div>
      <div class="mobile-btn">
        <button id="mobile_search_icon" data-id="mobile_search">
          <ion-icon
            data-id="mobile_search"
            class="seach-icon"
            name="search-outline"
          ></ion-icon>
        </button>
        
        <div data-id="link" data-value="/cart" data-item="/cart" id="cart_icon_mobile" class="cart__icon is-mobile">
          <!-- <a data-id="link" href="/cart" class="cart-btn">
            <span id="cart_no">0</span>
            <ion-icon class="cart-icon" name="cart-outline"></ion-icon>
          </a> -->
        </div>
        <button data-id="menu">
          <ion-icon data-id="menu" class="menu" name="menu-outline"></ion-icon>
        </button>
      </div>
      
      <!-- HEADER -->
     <div class="search__bar ">
      <div id="mobile_search" class="search__header hide-it">
        <input
          data-id="search"
          type="search"
          id="search_input"
          placeholder="Search products"
        />
          <button id="cancel_search_mobile" class="hide-it cancel-search"  data-id="open_search_mobile">
          cancel
        </button>
    
      </div>
         <nav id="nav" class="mobile__menu">
      <button class="close-menu" data-id="close_menu">
        <ion-icon
          data-id="close_menu"
          class="close-menu-icon"
          name="close-circle-outline"
        ></ion-icon>
      </button>
      <ul class="nav-list">
        <li data-id="link" data-value="shop_links" class="nav-items">
          <button
            data-id="toggle_link_list"
            data-value="Shop"
            class="link-toggler"
          >
            <span
               data-id="toggle_link_list"
            data-value="Shop"
             
              class="nav-link"
              >Shop
            </span>
            <ion-icon
            id="chevron_shop"
              data-id="toggle_link_list"
            data-value="Shop"
              class="icon-md"
              name="chevron-down-outline"
            ></ion-icon>
          </button>
  
          <div id="shop_links" class="link-options-container hide-nav">
            <ul class="link-options">
              <li>
                <h3>Weight Management</h3>
                <ul>
                  <li>
                    <a data-id="link" href="/shop?category=Formula-1-Shake"
                      >Formula 1 Shake
                  </li>
                    <li>
                    <a data-id="link" href="/shop?category=Protein-Solution"
                      >Protein Solutions</a
                    >
                  </li>
                    <li>
                    <a data-id="link" href="/shop?category=Healthy-Breakfast-Combos"
                      >Healthy Breakfast Combos</a
                    >
                  </li>
                  <li>
                    <a data-id="link" href="/shop?category=Enhances"
                      >Enhancers</a
                    >
                  </li>
  
                </ul>
              </li>
               <li>
                <h3>Targeted Nutrition</h3>
                <ul>
                  <li>
                    <a data-id="link" href="/shop?category=Digestive-Health"
                      >Digestive Health</a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <h3>Sports Nutrition</h3>
                <ul>
                  <li>
                    <a data-id="link" href="/shop?category=summercollection"
                      >Sports, Energy & Fitness</a
                    >
                  </li>
                  <li>
                    <a data-id="link" href="/shop?category=summercollection"
                      >Herbal24 Nutrition</a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <h3>Nutrition Accessories</h3>
                <ul>
                  <li>
                    <a data-id="link" href="/shop?category=summercollection">Accessories</a>
                  </li>
                </ul>
              </li>
            </ul>
            <div data-id="nav_filter" class="nav-filter"></div>
          </div>
        </li>
        <li data-id="link" data-value="shop_links" class="nav-items hide-it">
          <button
            data-id="toggle_link_list"
            data-value="empowerment"
            class="link-toggler"
          >
            <span
               data-id="toggle_link_list"
            data-value="empowerment"
             
              class="nav-link"
              > Empowerment
            </span>
            <ion-icon
            id="chevron_empowerment"
              data-id="toggle_link_list"
              data-value="empowerment"
              class="icon-md"
              name="chevron-down-outline"
            ></ion-icon>
          </button>
  
          <div id="empowerment_links" class="link-options-container hide-nav">
            <ul class="link-options">
              <li>
                <h3>Network Marketing</h3>
                <ul>
                  <li>
                    <a data-id="link" href="/shop?category=Formula-1-Shake"
                      >Network Marketing vs Pyramid Schemes
                  </li>
                    <li>
                    <a data-id="link" href="/shop?category=Protein-Solution"
                      >Protein Solutions</a
                    >
                  </li>
                    <li>
                    <a data-id="link" href="/shop?category=Healthy-Breakfast-Combos"
                      >Healthy Breakfast Combos</a
                    >
                  </li>
                  <li>
                    <a data-id="link" href="/shop?category=Enhances"
                      >Enhancers</a
                    >
                  </li>
  
                </ul>
              </li>
               <li>
                <h3>Woman Empowerment</h3>
                <ul>
                  <li>
                    <a data-id="link" href="/shop?category=Digestive-Health"
                      >Digestive Health</a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <h3>Youth Empowerment</h3>
                <ul>
                  <li>
                    <a data-id="link" href="/shop?category=summercollection"
                      >Sports, Energy & Fitness</a
                    >
                  </li>
                  <li>
                    <a data-id="link" href="/shop?category=summercollection"
                      >Herbal24 Nutrition</a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <h3>Mens health</h3>
                <ul>
                  <li>
                    <a data-id="link" href="/shop?category=summercollection">Accessories</a>
                  </li>
                </ul>
              </li>
            </ul>
            <div data-id="nav_filter" class="nav-filter"></div>
          </div>
        </li>
        <li data-id="link" data-value="businessopportunity" class="nav-items">
          <button
            data-id="toggle_link_list"
            data-value="Portfolio"
            class="link-toggler"
          >
            <a
              data-id="link"
              data-value="businessopportunity"
              data-id="link" href="/businessopportunity"
              class="nav-link"
              >Business Opportunity</a
            >
          </button>
        </li>
        <!-- <li data-id="link" data-value="businessopportunity" class="nav-items">
          <button
            data-id="toggle_link_list"
            data-value="Portfolio"
            class="link-toggler"
          >
            <a
              data-id="link"
              data-value="businessopportunity"
              data-id="link" href="/portfolio"
              class="nav-link"
              >Contact Us</a
            >
          </button>
        </li> -->
      </ul>
    </nav>
      <div id="search_results" class="translate-up">
        <div class="header">
          <p>
            Search results for
            <span id="search__keyword">(Ready to search..)</span>
          </p>
        </div>
        <div id="search_product_list" class="body">
          <div class="notification__products">
            <h3>
              No Products Found! <br /><br />
              <p>
                Most searched products: <br />
                <br />
                <button
                  class="search__hints"
                  data-value="Formula 1 shake"
                  data-id="search_hints"
                >
                  Formula 1 shake
                </button>
                <button
                  class="search__hints"
                  data-value=" Aloe"
                  data-id="search_hints"
                >
                  Aloe
                </button>
                <button
                  class="search__hints"
                  data-value="Tea"
                  data-id="search_hints"
                >
                  Tea
                </button>
              </p>
            </h3>
          </div>
        </div>
      </div>
    </div>
    <!-- NAV LIST -->
    </header>


    <!-- HEADER -->

    <main>
      <div id="app" style="margin-top: 100px">
        <!-- CONTENT -->
        <div class="product-wrapper">
          <div class="products" >
            <div class="cart-items-header">
              <button data-id="open_order_summary" class="show-summary-btn">
                <p data-id="open_order_summary">Order summary</p>
                <ion-icon
                 id="order_chevron"
                  data-id="open_order_summary"
                  class="icon-md"
                  name="chevron-down-outline"
                ></ion-icon>
              </button>
              <p id="summary_total_mobile">R${
                Totals.calculateTotal().subtotal
              }</p>
            </div>
            <div class="container">
              <div style="margin-top: 0" class="left-container center-it">
                ${Header.checkout()}
                <div class="checkout-information">
                  <div class="shipping-info">
                    <div class="cart-summary-item">
                      <p>Contact</p>
                      <p id="shipping_address_email">${props?.email}</p>
                      <a href="/shipping" class="info-link">change</a>
                    </div>
                    <div class="cart-summary-item">
                      <p>Ship to</p>
                      <p id="shipping_address_address">${props?.address}</p>
                      <a href="/shipping" class="info-link">change</a>
                    </div>

                    <div class="cart-summary-item">
                      <p>Method</p>
                      <p  class="cart-item-main">
                        ${props?.shippingMethod ?? "Standard"}
                      </p>
                      <p>${props?.shipping ?? `<b>R120</b>`}</p>
                    </div>
                  </div>

                  <div  class="row payment__title">
                    <div>
                      <p>Contact Information</p>
                      <p class="font-10">
                        All transactions are secure and encrypted by YOCO.
                      </p>
                    </div>
                  </div>
                  <!-- FORM -->
                  <div class="form-stacker">
                    <div class="form-accordion">
                      <div class="form-accordion-header">
                        <div class="form-label">
                          <input
                            type="radio"
                            data-id="bank_options"
                            id="credit_card"
                            value="credit_card"
                            name="credit_toggler"
                            class="payment-radio"
                            autocomplete="off"
                            checked="checked"
                          />
                          <label for="credit_card">Card Payment</label>
                        </div>
                        <div class="form-label-info">
                          <div class="bankcard-options">
                            <div class="bankcard-img">
                              <img
                                data-src="./images/mastercard_colour.svg"
                                alt="master cart"
                                class="img"
                              />
                            </div>
                            <div class="bankcard-img">
                              <img
                                data-src="./images/visa_colour.svg"
                                alt="visa"
                                class="img"
                              />
                            </div>
                            <div class="bankcard-img">
                              <img
                                data-src="./images/yoco_colour.svg"
                                alt="yoco"
                                class="img"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="credit_card_bank_body"
                        class="form-accordion-body"
                      >
                        <div class="form-accordion_inner">
                          <div class="container__img">
                            <img
                              style="width: 50px"
                              data-src="./images/share.png"
                              alt="redirect matblaze"
                              class="img"
                            />
                          </div>
                          <p>
                            You will be redirected to Yoco (site), to securely
                            complete the order.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="form-accordion hide-it">
                      <div class="form-accordion-header">
                        <div class="form-label">
                          <input
                            type="radio"
                            data-id="bank_options"
                            id="paypal"
                            value="paypal"
                            name="credit_toggler"
                            class="payment-radio"
                            autocomplete="off"
                          />
                          <label for="paypal">Bank Transfer</label>
                        </div>
                        <div class="form-label-info">
                          <div class="bankcard-options">
                            <div class="bankcard-img"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="paypal_bank_body"
                        class="form-accordion-body hide-it"
                      ></div>
                    </div>
                  </div>
                  <div class="row payment__title">
                    <p>Remember Me</p>
                  </div>
                  <div class="input-container">
                    <input type="checkbox" id="remember_me" />
                    <label class="margin-left" for="remember_me"
                      ><strong
                        >Save my information for a faster checkout</strong
                      ></label
                    >
                  </div>

                  <!-- FOOTER -->
                  <div class="checkout-getaway payment-checkout-getaway">
                    <div class="payment-supported">
                      <a data-id="link" data-value="/shipping" data-item="/shipping" href="/shipping" class="btn-md light-btn info-link">
                        <ion-icon  data-id="link" data-value="/shipping" data-item="/shipping" name="arrow-back-outline"></ion-icon>
                        <span  data-id="link" data-value="/shipping" data-item="/shipping" style="margin-left: 10px">
                          Return to shipping
                        </span>
                      </a>
                    </div>
                    <div class="checkout-container">
                      <button
                        data-id="pay_now"
                        id="pay_button"
                        data-value="yoco"
                        class="btn-md btn-long"
                      >
                        Pay R${Totals.calculateTotal().total}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
                <div
                id="order_summary"
                class="right-container right-checkout-details translate-Y"
              >
                <div id="cart_items" class="cart-items"></div>
                <div class="line-divider-dark"></div>
                <div class="gift-card"></div>
                <!-- <div class="line-divider-dark"></div> -->
               <div class="total-summary" id="summary_container">
                  
               </div>
            </div>
            </div>
          </div>
        </div>

        <!-- CONTENT -->
      </div>
    </main>

    ${Footer.checkout()}
      `;
  }
}
export default Payments;
