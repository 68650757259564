export const _shopProducts = [
  {
    description: {
      content:
        "Wake up to a refreshing herbal drink, containing 85mg Caffeine to help increase alertness whenever you need it. Low in kilojoules and infused with green and black tea, it is delicious enjoyed hot or cold.",
    },
    discount: "0",
    flavor: "Lemon",
    quantity: 1,
    category: "Enhances",
    salePrice: 0,
    id: "631726a22ad37d68dee064e4",
    flavors: ["Lemon", "Original ", "Raspberry", "Peach"],
    flavorsMap: [
      {
        id: "631726a22ad37d68dee064e4",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0255_SF_Instant%20Herbal_Beverage_Lemon_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Lemon",
      },
      {
        id: "631a41fafec35b82dfae192a",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/08-Aug/0105_Instant%20Herbal%20Beverage_Original%20Flavoured_50%20g_ZA.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Original ",
      },
      {
        id: "631a4240fec35b82dfae192c",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0256_SF_Instant%20herbal%20beverage%20raspberry_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Raspberry",
      },
      {
        id: "631a427bfec35b82dfae192e",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0257_SF_Instant%20herbal%20beverage%20peach_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Peach",
      },
    ],
    sale: false,
    featured: false,

    title: "Instant Herbal Beverage Lemon",
    images: [
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0255_SF_Instant%20Herbal_Beverage_Lemon_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/08-Aug/0105_Instant%20Herbal%20Beverage_Original%20Flavoured_50%20g_ZA.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0256_SF_Instant%20herbal%20beverage%20raspberry_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0257_SF_Instant%20herbal%20beverage%20peach_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
    ],
    price: 359,
    sizes: ["50g"],
    short_description: "Wake up to a refreshing herbal drink, containing 8...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix ½ teaspoon (approximately 1.7g) with 200-250ml of hot or cold water. Drink one or two glasses per day.",
          },
          {
            type: "list",
            content: [
              "Low kilojoule with approx 26 kJ per serving",
              "A unique blend of orange pekoe - a traditional black tea, and green tea with extracts of malvaflower, hibiscus flower and cardamom seed",
              "Our green tea is water extracted to give you the full spectrum of compounds found in green tea",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Multifibre is a delicious and easy way to help increase your daily fiber intake",
    },
    discount: "0",
    flavor: "Delicate Apple",
    quantity: 1,
    category: "Digestive Health",
    salePrice: 0,
    id: "6317291f2ad37d68dee064ef",
    flavors: [],
    flavorsMap: [],
    sale: false,
    featured: false,

    title: "Multifibre Drink",
    images: [
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/2554_SF_Multifibre%20Drink%20no%20added%20sugar_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
    ],
    price: 330,
    sizes: ["204g"],
    short_description: "Multifibre is a delicious and easy way to help inc...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix 1 measuring spoon (6,8 g) with 150 ml of water or  add a scoop to your favourite shake mix. Enjoy this product within a balanced and varied diet, as part of a healthy active lifestyle.",
          },
          {
            type: "list",
            content: [
              "5 g of fibre per serving",
              "6 fibre sources",
              "75 kJ per serving",
              "No added sugar",
              "Artificial sweetener free",
              "Contains both soluble and insoluble fibre",
              "Enjoy with water or mixed with your favourite Formula 1 shake",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Formula 1 is a delicious shake that provides protein and key nutrients",
    },
    discount: "0",
    flavor: "Strawberry Delight",
    quantity: 1,
    category: "Formula 1 Shake",
    salePrice: 0,
    id: "631751172ad37d68dee06501",
    flavors: [
      "Strawberry Delight",
      "Banana Cream",
      "Vanilla Cream",
      "Mint Chocolate",
      "Cookie Crunch",
      "Smooth Chocolate",
      "Spiced Apple",
      "Café Latte",
      "Summer Berries",
    ],
    flavorsMap: [
      {
        id: "631751172ad37d68dee06501",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Strawberry Delight",
      },
      {
        id: "6318459b9656372a65deeda8",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Banana Cream",
      },
      {
        id: "631845c29656372a65deedaa",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4466_F1%20Vanilla%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Vanilla Cream",
      },
      {
        id: "631845f39656372a65deedac",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4471_SF_Mint_Choc_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Mint Chocolate",
      },
      {
        id: "6318462a9656372a65deedae",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Cookie Crunch",
      },
      {
        id: "631846529656372a65deedb0",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4468_SF_Smooth_Chocolate_1300.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Smooth Chocolate",
      },
      {
        id: "631846779656372a65deedb2",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4464_F1%20Spiced%20Apple_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Spiced Apple",
      },
      {
        id: "6318469e9656372a65deedb4",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4465_F1%20Cafe%20Latte_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Café Latte",
      },
      {
        id: "6318455a9656372a65deeda6",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4470_F1%20Summer%20Berries_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Summer Berries",
      },
    ],
    sale: false,
    featured: false,

    title: "Formula 1 Shake Strawberry Delight",
    images: [
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2021/05-May/092K_SF_F1_Savoury_Mushroom_Herb_Shake_Mix_Square.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
    ],
    price: 529,
    sizes: ["550g"],
    short_description: "Formula 1 is a delicious shake that provides prote...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix two tablespoons of powder (26 grams) with 250 ml of semi-skimmed milk (low fat). To really treat your taste buds try adding your favourite fruit or combining your favourite flavours.",
          },
          {
            type: "list",
            content: [
              "An F1 shake is a convenient alternative to a high kilojoule breakfast, lunch or dinner",
              "Kilojoule-controlled when made according to directions: 920 kJ per serving.",
              " Contains a source of protein: 18 g per serving",
              "Source of some of your daily essential vitamins and minerals",
              "Developed by experts in nutrition",
              "Quick to whip up",
              "Personalise your shake with your favourite fruits",
              "Available in 9 delicious flavours as well as the F1 Free From - Lactose, soya, gluten free when made according to directions",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Formula 1 is a delicious shake that provides protein and key nutrients",
    },
    discount: "0",
    flavor: "Summer Berries",
    quantity: 1,
    category: "Formula 1 Shake",
    salePrice: 0,
    id: "6318455a9656372a65deeda6",
    flavors: [
      "Strawberry Delight",
      "Banana Cream",
      "Vanilla Cream",
      "Mint Chocolate",
      "Cookie Crunch",
      "Smooth Chocolate",
      "Spiced Apple",
      "Café Latte",
      "Summer Berries",
    ],
    flavorsMap: [
      {
        id: "631751172ad37d68dee06501",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Strawberry Delight",
      },
      {
        id: "6318459b9656372a65deeda8",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Banana Cream",
      },
      {
        id: "631845c29656372a65deedaa",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4466_F1%20Vanilla%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Vanilla Cream",
      },
      {
        id: "631845f39656372a65deedac",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4471_SF_Mint_Choc_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Mint Chocolate",
      },
      {
        id: "6318462a9656372a65deedae",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Cookie Crunch",
      },
      {
        id: "631846529656372a65deedb0",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4468_SF_Smooth_Chocolate_1300.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Smooth Chocolate",
      },
      {
        id: "631846779656372a65deedb2",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4464_F1%20Spiced%20Apple_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Spiced Apple",
      },
      {
        id: "6318469e9656372a65deedb4",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4465_F1%20Cafe%20Latte_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Café Latte",
      },
      {
        id: "6318455a9656372a65deeda6",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4470_F1%20Summer%20Berries_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Summer Berries",
      },
    ],
    sale: false,
    featured: false,

    title: "Formula 1 Shake Summer Berries",
    images: [
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4470_F1%20Summer%20Berries_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4466_F1%20Vanilla%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4471_SF_Mint_Choc_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4468_SF_Smooth_Chocolate_1300.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4464_F1%20Spiced%20Apple_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4465_F1%20Cafe%20Latte_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
    ],
    price: 529,
    sizes: ["550g"],
    short_description: "Formula 1 is a delicious shake that provides prote...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix two tablespoons of powder (26 grams) with 250 ml of semi-skimmed milk (low fat). To really treat your taste buds try adding your favourite fruit or combining your favourite flavours.",
          },
          {
            type: "list",
            content: [
              "An F1 shake is a convenient alternative to a high kilojoule breakfast, lunch or dinner",
              "Kilojoule-controlled when made according to directions: 920 kJ per serving.",
              " Contains a source of protein: 18 g per serving",
              "Source of some of your daily essential vitamins and minerals",
              "Developed by experts in nutrition",
              "Quick to whip up",
              "Personalise your shake with your favourite fruits",
              "Available in 9 delicious flavours as well as the F1 Free From - Lactose, soya, gluten free when made according to directions",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Formula 1 is a delicious shake that provides protein and key nutrients",
    },
    discount: "0",
    flavor: "Banana Cream",
    quantity: 1,
    category: "Formula 1 Shake",
    salePrice: 0,
    id: "6318459b9656372a65deeda8",
    flavors: [
      "Strawberry Delight",
      "Banana Cream",
      "Vanilla Cream",
      "Mint Chocolate",
      "Cookie Crunch",
      "Smooth Chocolate",
      "Spiced Apple",
      "Café Latte",
      "Summer Berries",
    ],
    flavorsMap: [
      {
        id: "631751172ad37d68dee06501",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Strawberry Delight",
      },
      {
        id: "6318459b9656372a65deeda8",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Banana Cream",
      },
      {
        id: "631845c29656372a65deedaa",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4466_F1%20Vanilla%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Vanilla Cream",
      },
      {
        id: "631845f39656372a65deedac",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4471_SF_Mint_Choc_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Mint Chocolate",
      },
      {
        id: "6318462a9656372a65deedae",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Cookie Crunch",
      },
      {
        id: "631846529656372a65deedb0",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4468_SF_Smooth_Chocolate_1300.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Smooth Chocolate",
      },
      {
        id: "631846779656372a65deedb2",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4464_F1%20Spiced%20Apple_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Spiced Apple",
      },
      {
        id: "6318469e9656372a65deedb4",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4465_F1%20Cafe%20Latte_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Café Latte",
      },
      {
        id: "6318455a9656372a65deeda6",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4470_F1%20Summer%20Berries_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Summer Berries",
      },
    ],
    sale: false,
    featured: false,

    title: "Formula 1 Shake Banana Cream",
    images: [
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4470_F1%20Summer%20Berries_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4466_F1%20Vanilla%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4471_SF_Mint_Choc_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4468_SF_Smooth_Chocolate_1300.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4464_F1%20Spiced%20Apple_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4465_F1%20Cafe%20Latte_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
    ],
    price: 529,
    sizes: ["550g"],
    short_description: "Formula 1 is a delicious shake that provides prote...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix two tablespoons of powder (26 grams) with 250 ml of semi-skimmed milk (low fat). To really treat your taste buds try adding your favourite fruit or combining your favourite flavours.",
          },
          {
            type: "list",
            content: [
              "An F1 shake is a convenient alternative to a high kilojoule breakfast, lunch or dinner",
              "Kilojoule-controlled when made according to directions: 920 kJ per serving.",
              " Contains a source of protein: 18 g per serving",
              "Source of some of your daily essential vitamins and minerals",
              "Developed by experts in nutrition",
              "Quick to whip up",
              "Personalise your shake with your favourite fruits",
              "Available in 9 delicious flavours as well as the F1 Free From - Lactose, soya, gluten free when made according to directions",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Formula 1 is a delicious shake that provides protein and key nutrients",
    },
    discount: "0",
    flavor: "Vanilla Cream",
    quantity: 1,
    category: "Formula 1 Shake",
    salePrice: 0,
    id: "631845c29656372a65deedaa",
    flavors: [
      "Strawberry Delight",
      "Banana Cream",
      "Vanilla Cream",
      "Mint Chocolate",
      "Cookie Crunch",
      "Smooth Chocolate",
      "Spiced Apple",
      "Café Latte",
      "Summer Berries",
    ],
    flavorsMap: [
      {
        id: "631751172ad37d68dee06501",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Strawberry Delight",
      },
      {
        id: "6318459b9656372a65deeda8",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Banana Cream",
      },
      {
        id: "631845c29656372a65deedaa",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4466_F1%20Vanilla%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Vanilla Cream",
      },
      {
        id: "631845f39656372a65deedac",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4471_SF_Mint_Choc_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Mint Chocolate",
      },
      {
        id: "6318462a9656372a65deedae",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Cookie Crunch",
      },
      {
        id: "631846529656372a65deedb0",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4468_SF_Smooth_Chocolate_1300.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Smooth Chocolate",
      },
      {
        id: "631846779656372a65deedb2",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4464_F1%20Spiced%20Apple_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Spiced Apple",
      },
      {
        id: "6318469e9656372a65deedb4",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4465_F1%20Cafe%20Latte_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Café Latte",
      },
      {
        id: "6318455a9656372a65deeda6",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4470_F1%20Summer%20Berries_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Summer Berries",
      },
    ],
    sale: false,
    featured: false,

    title: "Formula 1 Shake Vanilla Cream",
    images: [
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4466_F1%20Vanilla%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4470_F1%20Summer%20Berries_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4471_SF_Mint_Choc_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4468_SF_Smooth_Chocolate_1300.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4464_F1%20Spiced%20Apple_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4465_F1%20Cafe%20Latte_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
    ],
    price: 529,
    sizes: ["550g"],
    short_description: "Formula 1 is a delicious shake that provides prote...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix two tablespoons of powder (26 grams) with 250 ml of semi-skimmed milk (low fat). To really treat your taste buds try adding your favourite fruit or combining your favourite flavours.",
          },
          {
            type: "list",
            content: [
              "An F1 shake is a convenient alternative to a high kilojoule breakfast, lunch or dinner",
              "Kilojoule-controlled when made according to directions: 920 kJ per serving.",
              " Contains a source of protein: 18 g per serving",
              "Source of some of your daily essential vitamins and minerals",
              "Developed by experts in nutrition",
              "Quick to whip up",
              "Personalise your shake with your favourite fruits",
              "Available in 9 delicious flavours as well as the F1 Free From - Lactose, soya, gluten free when made according to directions",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Formula 1 is a delicious shake that provides protein and key nutrients",
    },
    discount: "0",
    flavor: "Mint Chocolate",
    quantity: 1,
    category: "Formula 1 Shake",
    salePrice: 0,
    id: "631845f39656372a65deedac",
    flavors: [
      "Strawberry Delight",
      "Banana Cream",
      "Vanilla Cream",
      "Mint Chocolate",
      "Cookie Crunch",
      "Smooth Chocolate",
      "Spiced Apple",
      "Café Latte",
      "Summer Berries",
    ],
    flavorsMap: [
      {
        id: "631751172ad37d68dee06501",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Strawberry Delight",
      },
      {
        id: "6318459b9656372a65deeda8",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Banana Cream",
      },
      {
        id: "631845c29656372a65deedaa",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4466_F1%20Vanilla%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Vanilla Cream",
      },
      {
        id: "631845f39656372a65deedac",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4471_SF_Mint_Choc_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Mint Chocolate",
      },
      {
        id: "6318462a9656372a65deedae",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Cookie Crunch",
      },
      {
        id: "631846529656372a65deedb0",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4468_SF_Smooth_Chocolate_1300.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Smooth Chocolate",
      },
      {
        id: "631846779656372a65deedb2",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4464_F1%20Spiced%20Apple_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Spiced Apple",
      },
      {
        id: "6318469e9656372a65deedb4",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4465_F1%20Cafe%20Latte_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Café Latte",
      },
      {
        id: "6318455a9656372a65deeda6",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4470_F1%20Summer%20Berries_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Summer Berries",
      },
    ],
    sale: false,
    featured: false,

    title: "Formula 1 Shake Mint Chocolate",
    images: [
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4471_SF_Mint_Choc_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4466_F1%20Vanilla%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4470_F1%20Summer%20Berries_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4468_SF_Smooth_Chocolate_1300.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4464_F1%20Spiced%20Apple_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4465_F1%20Cafe%20Latte_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
    ],
    price: 529,
    sizes: ["550g"],
    short_description: "Formula 1 is a delicious shake that provides prote...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix two tablespoons of powder (26 grams) with 250 ml of semi-skimmed milk (low fat). To really treat your taste buds try adding your favourite fruit or combining your favourite flavours.",
          },
          {
            type: "list",
            content: [
              "An F1 shake is a convenient alternative to a high kilojoule breakfast, lunch or dinner",
              "Kilojoule-controlled when made according to directions: 920 kJ per serving.",
              " Contains a source of protein: 18 g per serving",
              "Source of some of your daily essential vitamins and minerals",
              "Developed by experts in nutrition",
              "Quick to whip up",
              "Personalise your shake with your favourite fruits",
              "Available in 9 delicious flavours as well as the F1 Free From - Lactose, soya, gluten free when made according to directions",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Formula 1 is a delicious shake that provides protein and key nutrients",
    },
    discount: "0",
    flavor: "Cookie Crunch",
    quantity: 1,
    category: "Formula 1 Shake",
    salePrice: 0,
    id: "6318462a9656372a65deedae",
    flavors: [
      "Strawberry Delight",
      "Banana Cream",
      "Vanilla Cream",
      "Mint Chocolate",
      "Cookie Crunch",
      "Smooth Chocolate",
      "Spiced Apple",
      "Café Latte",
      "Summer Berries",
    ],
    flavorsMap: [
      {
        id: "631751172ad37d68dee06501",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Strawberry Delight",
      },
      {
        id: "6318459b9656372a65deeda8",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Banana Cream",
      },
      {
        id: "631845c29656372a65deedaa",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4466_F1%20Vanilla%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Vanilla Cream",
      },
      {
        id: "631845f39656372a65deedac",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4471_SF_Mint_Choc_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Mint Chocolate",
      },
      {
        id: "6318462a9656372a65deedae",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Cookie Crunch",
      },
      {
        id: "631846529656372a65deedb0",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4468_SF_Smooth_Chocolate_1300.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Smooth Chocolate",
      },
      {
        id: "631846779656372a65deedb2",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4464_F1%20Spiced%20Apple_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Spiced Apple",
      },
      {
        id: "6318469e9656372a65deedb4",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4465_F1%20Cafe%20Latte_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Café Latte",
      },
      {
        id: "6318455a9656372a65deeda6",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4470_F1%20Summer%20Berries_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Summer Berries",
      },
    ],
    sale: false,
    featured: false,

    title: "Formula 1 Shake Cookie Crunch",
    images: [
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4466_F1%20Vanilla%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4470_F1%20Summer%20Berries_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4471_SF_Mint_Choc_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4468_SF_Smooth_Chocolate_1300.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4464_F1%20Spiced%20Apple_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4465_F1%20Cafe%20Latte_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
    ],
    price: 529,
    sizes: ["550g"],
    short_description: "Formula 1 is a delicious shake that provides prote...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix two tablespoons of powder (26 grams) with 250 ml of semi-skimmed milk (low fat). To really treat your taste buds try adding your favourite fruit or combining your favourite flavours.",
          },
          {
            type: "list",
            content: [
              "An F1 shake is a convenient alternative to a high kilojoule breakfast, lunch or dinner",
              "Kilojoule-controlled when made according to directions: 920 kJ per serving.",
              " Contains a source of protein: 18 g per serving",
              "Source of some of your daily essential vitamins and minerals",
              "Developed by experts in nutrition",
              "Quick to whip up",
              "Personalise your shake with your favourite fruits",
              "Available in 9 delicious flavours as well as the F1 Free From - Lactose, soya, gluten free when made according to directions",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Formula 1 is a delicious shake that provides protein and key nutrients",
    },
    discount: "0",
    flavor: "Smooth Chocolate",
    quantity: 1,
    category: "Formula 1 Shake",
    salePrice: 0,
    id: "631846529656372a65deedb0",
    flavors: [
      "Strawberry Delight",
      "Banana Cream",
      "Vanilla Cream",
      "Mint Chocolate",
      "Cookie Crunch",
      "Smooth Chocolate",
      "Spiced Apple",
      "Café Latte",
      "Summer Berries",
    ],
    flavorsMap: [
      {
        id: "631751172ad37d68dee06501",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Strawberry Delight",
      },
      {
        id: "6318459b9656372a65deeda8",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Banana Cream",
      },
      {
        id: "631845c29656372a65deedaa",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4466_F1%20Vanilla%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Vanilla Cream",
      },
      {
        id: "631845f39656372a65deedac",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4471_SF_Mint_Choc_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Mint Chocolate",
      },
      {
        id: "6318462a9656372a65deedae",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Cookie Crunch",
      },
      {
        id: "631846529656372a65deedb0",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4468_SF_Smooth_Chocolate_1300.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Smooth Chocolate",
      },
      {
        id: "631846779656372a65deedb2",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4464_F1%20Spiced%20Apple_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Spiced Apple",
      },
      {
        id: "6318469e9656372a65deedb4",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4465_F1%20Cafe%20Latte_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Café Latte",
      },
      {
        id: "6318455a9656372a65deeda6",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4470_F1%20Summer%20Berries_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Summer Berries",
      },
    ],
    sale: false,
    featured: false,

    title: "Formula 1 Shake Smooth Chocolate",
    images: [
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4468_SF_Smooth_Chocolate_1300.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4466_F1%20Vanilla%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4470_F1%20Summer%20Berries_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4471_SF_Mint_Choc_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4464_F1%20Spiced%20Apple_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4465_F1%20Cafe%20Latte_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
    ],
    price: 529,
    sizes: ["550g"],
    short_description: "Formula 1 is a delicious shake that provides prote...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix two tablespoons of powder (26 grams) with 250 ml of semi-skimmed milk (low fat). To really treat your taste buds try adding your favourite fruit or combining your favourite flavours.",
          },
          {
            type: "list",
            content: [
              "An F1 shake is a convenient alternative to a high kilojoule breakfast, lunch or dinner",
              "Kilojoule-controlled when made according to directions: 920 kJ per serving.",
              " Contains a source of protein: 18 g per serving",
              "Source of some of your daily essential vitamins and minerals",
              "Developed by experts in nutrition",
              "Quick to whip up",
              "Personalise your shake with your favourite fruits",
              "Available in 9 delicious flavours as well as the F1 Free From - Lactose, soya, gluten free when made according to directions",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Formula 1 is a delicious shake that provides protein and key nutrients",
    },
    discount: "0",
    flavor: "Spiced Apple",
    quantity: 1,
    category: "Formula 1 Shake",
    salePrice: 0,
    id: "631846779656372a65deedb2",
    flavors: [
      "Strawberry Delight",
      "Banana Cream",
      "Vanilla Cream",
      "Mint Chocolate",
      "Cookie Crunch",
      "Smooth Chocolate",
      "Spiced Apple",
      "Café Latte",
      "Summer Berries",
    ],
    flavorsMap: [
      {
        id: "631751172ad37d68dee06501",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Strawberry Delight",
      },
      {
        id: "6318459b9656372a65deeda8",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Banana Cream",
      },
      {
        id: "631845c29656372a65deedaa",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4466_F1%20Vanilla%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Vanilla Cream",
      },
      {
        id: "631845f39656372a65deedac",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4471_SF_Mint_Choc_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Mint Chocolate",
      },
      {
        id: "6318462a9656372a65deedae",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Cookie Crunch",
      },
      {
        id: "631846529656372a65deedb0",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4468_SF_Smooth_Chocolate_1300.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Smooth Chocolate",
      },
      {
        id: "631846779656372a65deedb2",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4464_F1%20Spiced%20Apple_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Spiced Apple",
      },
      {
        id: "6318469e9656372a65deedb4",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4465_F1%20Cafe%20Latte_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Café Latte",
      },
      {
        id: "6318455a9656372a65deeda6",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4470_F1%20Summer%20Berries_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Summer Berries",
      },
    ],
    sale: false,
    featured: false,

    title: "Formula 1 Shake Spiced Apple",
    images: [
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4464_F1%20Spiced%20Apple_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4466_F1%20Vanilla%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4470_F1%20Summer%20Berries_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4471_SF_Mint_Choc_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4468_SF_Smooth_Chocolate_1300.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4465_F1%20Cafe%20Latte_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
    ],
    price: 529,
    sizes: ["550g"],
    short_description: "Formula 1 is a delicious shake that provides prote...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix two tablespoons of powder (26 grams) with 250 ml of semi-skimmed milk (low fat). To really treat your taste buds try adding your favourite fruit or combining your favourite flavours.",
          },
          {
            type: "list",
            content: [
              "An F1 shake is a convenient alternative to a high kilojoule breakfast, lunch or dinner",
              "Kilojoule-controlled when made according to directions: 920 kJ per serving.",
              " Contains a source of protein: 18 g per serving",
              "Source of some of your daily essential vitamins and minerals",
              "Developed by experts in nutrition",
              "Quick to whip up",
              "Personalise your shake with your favourite fruits",
              "Available in 9 delicious flavours as well as the F1 Free From - Lactose, soya, gluten free when made according to directions",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Formula 1 is a delicious shake that provides protein and key nutrients",
    },
    discount: "0",
    flavor: "Café Latte",
    quantity: 1,
    category: "Formula 1 Shake",
    salePrice: 0,
    id: "6318469e9656372a65deedb4",
    flavors: [
      "Strawberry Delight",
      "Banana Cream",
      "Vanilla Cream",
      "Mint Chocolate",
      "Cookie Crunch",
      "Smooth Chocolate",
      "Spiced Apple",
      "Café Latte",
      "Summer Berries",
    ],
    flavorsMap: [
      {
        id: "631751172ad37d68dee06501",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Strawberry Delight",
      },
      {
        id: "6318459b9656372a65deeda8",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Banana Cream",
      },
      {
        id: "631845c29656372a65deedaa",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4466_F1%20Vanilla%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Vanilla Cream",
      },
      {
        id: "631845f39656372a65deedac",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4471_SF_Mint_Choc_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Mint Chocolate",
      },
      {
        id: "6318462a9656372a65deedae",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Cookie Crunch",
      },
      {
        id: "631846529656372a65deedb0",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4468_SF_Smooth_Chocolate_1300.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Smooth Chocolate",
      },
      {
        id: "631846779656372a65deedb2",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4464_F1%20Spiced%20Apple_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Spiced Apple",
      },
      {
        id: "6318469e9656372a65deedb4",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4465_F1%20Cafe%20Latte_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Café Latte",
      },
      {
        id: "6318455a9656372a65deeda6",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4470_F1%20Summer%20Berries_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Summer Berries",
      },
    ],
    sale: false,
    featured: false,

    title: "Formula 1 Shake Café Latte",
    images: [
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4465_F1%20Cafe%20Latte_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4466_F1%20Vanilla%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4462_F1%20Banana%20Cream_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4470_F1%20Summer%20Berries_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4463_F1%20Strawberry%20Delight_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4471_SF_Mint_Choc_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4467_F1%20Cookie%20Crunch_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4468_SF_Smooth_Chocolate_1300.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/04-Apr/4464_F1%20Spiced%20Apple_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
    ],
    price: 529,
    sizes: ["550g"],
    short_description: "Formula 1 is a delicious shake that provides prote...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix two tablespoons of powder (26 grams) with 250 ml of semi-skimmed milk (low fat). To really treat your taste buds try adding your favourite fruit or combining your favourite flavours.",
          },
          {
            type: "list",
            content: [
              "An F1 shake is a convenient alternative to a high kilojoule breakfast, lunch or dinner",
              "Kilojoule-controlled when made according to directions: 920 kJ per serving.",
              " Contains a source of protein: 18 g per serving",
              "Source of some of your daily essential vitamins and minerals",
              "Developed by experts in nutrition",
              "Quick to whip up",
              "Personalise your shake with your favourite fruits",
              "Available in 9 delicious flavours as well as the F1 Free From - Lactose, soya, gluten free when made according to directions",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Wake up to a refreshing herbal drink, containing 85mg Caffeine to help increase alertness whenever you need it. Low in kilojoules and infused with green and black tea, it is delicious enjoyed hot or cold.",
    },
    discount: "0",
    flavor: "Original",
    quantity: 1,
    category: "Enhances",
    salePrice: 0,
    id: "631a41fafec35b82dfae192a",
    flavors: ["Original ", "Lemon", "Raspberry", "Peach"],
    flavorsMap: [
      {
        id: "631a41fafec35b82dfae192a",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/08-Aug/0105_Instant%20Herbal%20Beverage_Original%20Flavoured_50%20g_ZA.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Original ",
      },
      {
        id: "631726a22ad37d68dee064e4",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0255_SF_Instant%20Herbal_Beverage_Lemon_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Lemon",
      },
      {
        id: "631a4240fec35b82dfae192c",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0256_SF_Instant%20herbal%20beverage%20raspberry_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Raspberry",
      },
      {
        id: "631a427bfec35b82dfae192e",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0257_SF_Instant%20herbal%20beverage%20peach_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Peach",
      },
    ],
    sale: false,
    featured: false,

    title: "Instant Herbal Beverage Original",
    images: [
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/08-Aug/0105_Instant%20Herbal%20Beverage_Original%20Flavoured_50%20g_ZA.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0255_SF_Instant%20Herbal_Beverage_Lemon_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0256_SF_Instant%20herbal%20beverage%20raspberry_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0257_SF_Instant%20herbal%20beverage%20peach_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
    ],
    price: 359,
    sizes: ["50g"],
    short_description: "Wake up to a refreshing herbal drink, containing 8...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix ½ teaspoon (approximately 1.7g) with 200-250ml of hot or cold water. Drink one or two glasses per day.",
          },
          {
            type: "list",
            content: [
              "Low kilojoule with approx 26 kJ per serving",
              "A unique blend of orange pekoe - a traditional black tea, and green tea with extracts of malvaflower, hibiscus flower and cardamom seed",
              "Our green tea is water extracted to give you the full spectrum of compounds found in green tea",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Wake up to a refreshing herbal drink, containing 85mg Caffeine to help increase alertness whenever you need it. Low in kilojoules and infused with green and black tea, it is delicious enjoyed hot or cold.",
    },
    discount: "0",
    flavor: "Raspberry",
    quantity: 1,
    category: "Enhances",
    salePrice: 0,
    id: "631a4240fec35b82dfae192c",
    flavors: ["Raspberry", "Peach", "Lemon", "Original "],
    flavorsMap: [
      {
        id: "631a4240fec35b82dfae192c",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0256_SF_Instant%20herbal%20beverage%20raspberry_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Raspberry",
      },
      {
        id: "631a427bfec35b82dfae192e",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0257_SF_Instant%20herbal%20beverage%20peach_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Peach",
      },
      {
        id: "631726a22ad37d68dee064e4",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0255_SF_Instant%20Herbal_Beverage_Lemon_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Lemon",
      },
      {
        id: "631a41fafec35b82dfae192a",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/08-Aug/0105_Instant%20Herbal%20Beverage_Original%20Flavoured_50%20g_ZA.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Original ",
      },
    ],
    sale: false,
    featured: false,

    title: "Instant Herbal Beverage Raspberry",
    images: [
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0256_SF_Instant%20herbal%20beverage%20raspberry_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/08-Aug/0105_Instant%20Herbal%20Beverage_Original%20Flavoured_50%20g_ZA.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0255_SF_Instant%20Herbal_Beverage_Lemon_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0257_SF_Instant%20herbal%20beverage%20peach_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
    ],
    price: 359,
    sizes: ["50g"],
    short_description: "Wake up to a refreshing herbal drink, containing 8...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix ½ teaspoon (approximately 1.7g) with 200-250ml of hot or cold water. Drink one or two glasses per day.",
          },
          {
            type: "list",
            content: [
              "Low kilojoule with approx 26 kJ per serving",
              "A unique blend of orange pekoe - a traditional black tea, and green tea with extracts of malvaflower, hibiscus flower and cardamom seed",
              "Our green tea is water extracted to give you the full spectrum of compounds found in green tea",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Wake up to a refreshing herbal drink, containing 85mg Caffeine to help increase alertness whenever you need it. Low in kilojoules and infused with green and black tea, it is delicious enjoyed hot or cold.",
    },
    discount: "0",
    flavor: "Peach",
    quantity: 1,
    category: "Enhances",
    salePrice: 0,
    id: "631a427bfec35b82dfae192e",
    flavors: ["Peach", "Lemon", "Original ", "Raspberry"],
    flavorsMap: [
      {
        id: "631a427bfec35b82dfae192e",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0257_SF_Instant%20herbal%20beverage%20peach_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Peach",
      },
      {
        id: "631726a22ad37d68dee064e4",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0255_SF_Instant%20Herbal_Beverage_Lemon_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Lemon",
      },
      {
        id: "631a41fafec35b82dfae192a",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/08-Aug/0105_Instant%20Herbal%20Beverage_Original%20Flavoured_50%20g_ZA.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Original ",
      },
      {
        id: "631a4240fec35b82dfae192c",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0256_SF_Instant%20herbal%20beverage%20raspberry_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Raspberry",
      },
    ],
    sale: false,
    featured: false,

    title: "Instant Herbal Beverage Peach",
    images: [
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0257_SF_Instant%20herbal%20beverage%20peach_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2020/08-Aug/0105_Instant%20Herbal%20Beverage_Original%20Flavoured_50%20g_ZA.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0255_SF_Instant%20Herbal_Beverage_Lemon_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0256_SF_Instant%20herbal%20beverage%20raspberry_50g_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
    ],
    price: 359,
    sizes: ["50g"],
    short_description: "Wake up to a refreshing herbal drink, containing 8...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix ½ teaspoon (approximately 1.7g) with 200-250ml of hot or cold water. Drink one or two glasses per day.",
          },
          {
            type: "list",
            content: [
              "Low kilojoule with approx 26 kJ per serving",
              "A unique blend of orange pekoe - a traditional black tea, and green tea with extracts of malvaflower, hibiscus flower and cardamom seed",
              "Our green tea is water extracted to give you the full spectrum of compounds found in green tea",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "A tasty and kilojoule controlled drink, to help you stay hydrated. Simply add it to water for a healthier alternative to high-kilojoule, sugary soft drinks.",
    },
    discount: "0",
    flavor: "Original",
    quantity: 1,
    category: "Digestive Health",
    salePrice: 0,
    id: "631a46fafec35b82dfae194f",
    flavors: ["Original", "Mango"],
    flavorsMap: [
      {
        id: "631a46fafec35b82dfae194f",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0006_SF_Aloe%20Original%20Concentrate_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Original",
      },
      {
        id: "631a4720fec35b82dfae1951",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2021/05-May/1065_ZA_400.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Mango",
      },
    ],
    sale: false,
    featured: false,

    title: "Herbal Aloe Concentrate Original",
    images: [
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2021/05-May/1065_ZA_400.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0006_SF_Aloe%20Original%20Concentrate_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
    ],
    price: 528,
    sizes: ["473ml"],
    short_description: "A tasty and kilojoule controlled drink, to help yo...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix 3 capfuls (15 ml) with 120 ml of water. To prepare 1 litre of ready to consume Herbal Aloe Concentrate Drink, pour 8 tablespoons (120 ml) concentrate into a litre container and fill with water. Refrigerate any unused portion of the concentrate and consume as soon as possible.",
          },
          {
            type: "list",
            content: [
              "Contains 40% Aloe Vera juice which is derived from the whole Aloe vera leaf",
              "The Aloe vera leaf is carefully picked and hand filleted to ensure the highest level of purity",
              "The Aloe gel is extracted using cold processing which is the best way to achieve the highest quality of gel",
              "Liven up your water with a refreshing taste to help you to achieve adequate fluid intake of 2-2.5 litres per day.",
              "Mango flavoured:  artificial flavouring and colourant free",
              "Original flavoured:  contains citrus to give your water a refreshing flavour twist",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "A tasty and kilojoule controlled drink, to help you stay hydrated. Simply add it to water for a healthier alternative to high-kilojoule, sugary soft drinks.",
    },
    discount: "0",
    flavor: "Mango",
    quantity: 1,
    category: "Digestive Health",
    salePrice: 0,
    id: "631a4720fec35b82dfae1951",
    flavors: ["Mango", "Original"],
    flavorsMap: [
      {
        id: "631a4720fec35b82dfae1951",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2021/05-May/1065_ZA_400.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Mango",
      },
      {
        id: "631a46fafec35b82dfae194f",
        photo:
          "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0006_SF_Aloe%20Original%20Concentrate_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
        flavor: "Original",
      },
    ],
    sale: false,
    featured: false,

    title: "Herbal Aloe Concentrate Mango",
    images: [
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2021/05-May/1065_ZA_400.png/_jcr_content/renditions/cq5dam.web.800.800.png",
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/0006_SF_Aloe%20Original%20Concentrate_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
    ],
    price: 528,
    sizes: ["473ml"],
    short_description: "A tasty and kilojoule controlled drink, to help yo...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix 3 capfuls (15 ml) with 120 ml of water. To prepare 1 litre of ready to consume Herbal Aloe Concentrate Drink, pour 8 tablespoons (120 ml) concentrate into a litre container and fill with water. Refrigerate any unused portion of the concentrate and consume as soon as possible.",
          },
          {
            type: "list",
            content: [
              "Contains 40% Aloe Vera juice which is derived from the whole Aloe vera leaf",
              "The Aloe vera leaf is carefully picked and hand filleted to ensure the highest level of purity",
              "The Aloe gel is extracted using cold processing which is the best way to achieve the highest quality of gel",
              "Liven up your water with a refreshing taste to help you to achieve adequate fluid intake of 2-2.5 litres per day.",
              "Mango flavoured:  artificial flavouring and colourant free",
              "Original flavoured:  contains citrus to give your water a refreshing flavour twist",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Wake up to a refreshing herbal drink,  containing 85mg Caffeine to help increase alertness whenever you need it. Low in kilojoules and infused with green and black tea, it is delicious enjoyed hot or cold. Formula 1 is a delicious shake that provides protein and key nutrients",
    },
    discount: "0",
    flavor: "choose flavor",
    quantity: 1,
    category: "Healthy Breakfast Combos",
    salePrice: 0,
    id: "633c025f0cf5ad22839143aa",
    flavors: [""],
    flavorsMap: [
      {
        id: "",
        photo: "",
        flavor: "",
      },
    ],
    sale: false,
    featured: false,

    title: "Shake + Tea",
    images: ["https://i.ibb.co/0nqbvGm/shakeTea.png"],
    price: 710,
    sizes: ["550g + 50g"],
    short_description: "Wake up to a refreshing herbal drink,  containing ...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix ½ teaspoon (approximately 1.7g) with 200-250ml of hot or cold water. Drink one or two glasses per day.",
          },
          {
            type: "list",
            content: [
              "Low kilojoule with approx 26 kJ per serving",
              "A unique blend of orange pekoe - a traditional black tea, and green tea with extracts of malvaflower, hibiscus flower and cardamom seed",
              "Our green tea is water extracted to give you the full spectrum of compounds found in green tea",
              "An F1 shake is a convenient alternative to a high kilojoule breakfast, lunch or dinner",
              "Kilojoule-controlled when made according to directions: 920 kJ per serving.",
              " Contains a source of protein: 18 g per serving",
              "Source of some of your daily essential vitamins and minerals",
              "Developed by experts in nutrition",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Wake up to a refreshing herbal drink, containing 85mg Caffeine to help increase alertness whenever you need it. Low in kilojoules and infused with green and black tea, it is delicious enjoyed hot or cold + Formula 1 is a delicious shake that provides protein and key nutrients + A tasty and kilojoule controlled drink, to help you stay hydrated. Simply add it to water for a healthier alternative to high-kilojoule, sugary soft drinks",
    },
    discount: "0",
    flavor: "choose flavor",
    quantity: 1,
    category: "Healthy Breakfast Combos",
    salePrice: 0,
    id: "633c04c97d01ad77bf147efc",
    flavors: [""],
    flavorsMap: [
      {
        id: "",
        photo: "",
        flavor: "",
      },
    ],
    sale: false,
    featured: false,

    title: "Shake + Tea + Aloe",
    images: ["https://i.ibb.co/qNqszm8/shake-Tea-Aloe.png"],
    price: 1206,
    sizes: ["550g + 50g"],
    short_description: "Wake up to a refreshing herbal drink, containing 8...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix ½ teaspoon (approximately 1.7g) with 200-250ml of hot or cold water. Drink one or two glasses per day.",
          },
          {
            type: "list",
            content: [
              "Low kilojoule with approx 26 kJ per serving",
              "A unique blend of orange pekoe - a traditional black tea, and green tea with extracts of malvaflower, hibiscus flower and cardamom seed",
              "Our green tea is water extracted to give you the full spectrum of compounds found in green tea",
              "An F1 shake is a convenient alternative to a high kilojoule breakfast, lunch or dinner",
              "Kilojoule-controlled when made according to directions: 920 kJ per serving.",
              " Contains a source of protein: 18 g per serving",
              "Source of some of your daily essential vitamins and minerals",
              "Developed by experts in nutrition",
              "Contains 40% Aloe Vera juice which is derived from the whole Aloe vera leaf",
              "The Aloe vera leaf is carefully picked and hand filleted to ensure the highest level of purity",
              "The Aloe gel is extracted using cold processing which is the best way to achieve the highest quality of gel",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Wake up to a refreshing herbal drink, containing 85mg Caffeine to help increase alertness whenever you need it. Low in kilojoules and infused with green and black tea, it is delicious enjoyed hot or cold + Formula 1 is a delicious shake that provides protein and key nutrients + A tasty and kilojoule controlled drink, to help you stay hydrated. Simply add it to water for a healthier alternative to high-kilojoule, sugary soft drinks + a delicious and easy way to help increase your daily fiber intake",
    },
    discount: "0",
    flavor: "choose flavor",
    quantity: 1,
    category: "Healthy Breakfast Combos",
    salePrice: 0,
    id: "633c05ca7d01ad77bf147efe",
    flavors: [""],
    flavorsMap: [
      {
        id: "",
        photo: "",
        flavor: "",
      },
    ],
    sale: false,
    featured: false,

    title: "Shake + Aloe + Fibre + Tea",
    images: ["https://i.ibb.co/Lk6stcb/shake-Tea-Aloe-Fibre.png"],
    price: 1516,
    sizes: ["550g + 473ml + 204g + 50g"],
    short_description: "Wake up to a refreshing herbal drink, containing 8...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix ½ teaspoon (approximately 1.7g) with 200-250ml of hot or cold water. Drink one or two glasses per day.",
          },
          {
            type: "list",
            content: [
              "Low kilojoule with approx 26 kJ per serving",
              "A unique blend of orange pekoe - a traditional black tea, and green tea with extracts of malvaflower, hibiscus flower and cardamom seed",
              "Our green tea is water extracted to give you the full spectrum of compounds found in green tea",
              "An F1 shake is a convenient alternative to a high kilojoule breakfast, lunch or dinner",
              "Kilojoule-controlled when made according to directions: 920 kJ per serving.",
              " Contains a source of protein: 18 g per serving",
              "Source of some of your daily essential vitamins and minerals",
              "Developed by experts in nutrition",
              "Contains 40% Aloe Vera juice which is derived from the whole Aloe vera leaf",
              "The Aloe vera leaf is carefully picked and hand filleted to ensure the highest level of purity",
              "The Aloe gel is extracted using cold processing which is the best way to achieve the highest quality of gel",
              "5 g of fibre per serving",
              "6 fibre sources",
              "75 kJ per serving",
              "No added sugar",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Wake up to a refreshing herbal drink, containing 85mg Caffeine to help increase alertness whenever you need it. Low in kilojoules and infused with green and black tea, it is delicious enjoyed hot or cold + Formula 1 is a delicious shake that provides protein and key nutrients + A tasty and kilojoule controlled drink, to help you stay hydrated. Simply add it to water for a healthier alternative to high-kilojoule, sugary soft drinks + a delicious and easy way to help increase your daily fiber intake + Protein Drink Mix which is a great way to help you reach your daily protein intake",
    },
    discount: "0",
    flavor: "choose flavor",
    quantity: 1,
    category: "Healthy Breakfast Combos",
    salePrice: 0,
    id: "633c06e57d01ad77bf147f00",
    flavors: [""],
    flavorsMap: [
      {
        id: "",
        photo: "",
        flavor: "",
      },
    ],
    sale: false,
    featured: false,

    title: "Stamp breakfast",
    images: ["https://i.ibb.co/gvPPRQt/stampbreakfast.png"],
    price: 1980,
    sizes: ["550g + 473ml + 204g + 50g + 588g"],
    short_description: "Wake up to a refreshing herbal drink, containing 8...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Mix ½ teaspoon (approximately 1.7g) with 200-250ml of hot or cold water. Drink one or two glasses per day.",
          },
          {
            type: "list",
            content: [
              "Low kilojoule with approx 26 kJ per serving",
              "A unique blend of orange pekoe - a traditional black tea, and green tea with extracts of malvaflower, hibiscus flower and cardamom seed",
              "Our green tea is water extracted to give you the full spectrum of compounds found in green tea",
              "An F1 shake is a convenient alternative to a high kilojoule breakfast, lunch or dinner",
              "Kilojoule-controlled when made according to directions: 920 kJ per serving.",
              " Contains a source of protein: 18 g per serving",
              "Source of some of your daily essential vitamins and minerals",
              "Developed by experts in nutrition",
              "Contains 40% Aloe Vera juice which is derived from the whole Aloe vera leaf",
              "The Aloe vera leaf is carefully picked and hand filleted to ensure the highest level of purity",
              "The Aloe gel is extracted using cold processing which is the best way to achieve the highest quality of gel",
              "5 g of fibre per serving",
              "6 fibre sources",
              "75 kJ per serving",
              "No added sugar",
              "15g of high quality protein",
              "20 essential vitamins and minerals",
              "7g of carbohydrate",
            ],
          },
        ],
      },
    ],
  },
  {
    description: {
      content:
        "Protein Drink Mix is a great way to help you reach your daily protein intake. Our body cannot store protein; neither can it be produced internally, so it’s vital we get enough protein each day. How much protein do I need? Herbalife recommends that active individuals consume about 1-2 grams of protein per kilogram of body weight. For example, a male weighing 70 kg will need between 70-140 g of protein, whereas a female weighing 45 kg will need between 45 g-90 g of protein each day.",
    },
    discount: "0",
    flavor: "Vanilla",
    quantity: 1,
    category: "Protein Solution",
    salePrice: 0,
    id: "633c0e2d7d01ad77bf147f40",
    flavors: ["Vanilla"],
    flavorsMap: [
      {
        id: "",
        photo: "",
        flavor: "",
      },
    ],
    sale: false,
    featured: false,

    title: "Protein Drink Mix Vanilla",
    images: [
      "https://assets.herbalifenutrition.com/content/dam/regional/emea/en_za/consumable_content/product-catalog-assets/images/2018/05-May/2600_SF_Protein%20Drink%20Mix_%20Vanilla_Square_1300px.png/_jcr_content/renditions/cq5dam.web.800.800.png",
    ],
    price: 554,
    sizes: ["588g"],
    short_description: "Protein Drink Mix is a great way to help you reach...",
    accordion: [
      {
        _id: "how_to_use",
        title: "How to Use Product",
        children: [
          {
            type: "para",
            content:
              "Prepare it as a tasty, kilojoule controlled high protein snack. Mix 2 scoops (27g) into 500 ml of water OR Add it to your favourite Formula 1 Shake for an indulgent, rich, creamy drink. Add one scoop(14g) of Protein Drink Mix along with two scoops of your favourite F1 Shake",
          },
          {
            type: "list",
            content: [
              "15g of high quality protein",
              "20 essential vitamins and minerals",
              "7g of carbohydrates",
              "Can be made with water",
              "Delicious vanilla flavour",
              "457 kJ per serving when mixed with 250 ml water",
            ],
          },
        ],
      },
    ],
  },
];

function convertToGoogleSchema(products) {
  var items = [];
  products.forEach(function (product) {
    var item = {
      "@context": "http://schema.org/",
      "@type": "Product",
      name: product.title,
      description: product.description.content,
      sku: product.id,
      brand: {
        "@type": "Brand",
        name: "Your Brand Name",
      },
      offers: {
        "@type": "Offer",
        priceCurrency: "USD", // Modify this if your currency is different
        price: product.price,
        availability: "http://schema.org/InStock",
        seller: {
          "@type": "Organization",
          name: "Your Company Name",
        },
      },
      image: product.images[0], // Use the first image as the main image
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "4.5", // Modify this to the actual rating value
        reviewCount: "10", // Modify this to the actual review count
      },
    };
    items.push(item);
  });
  return items;
}

// console.log(convertToGoogleSchema(_shopProducts));
