import View from "../../../../view/View.js";
import {globalState, setState} from "../../../../model/model.js";

const CartNotification = (function () {
  // State
  const view = new View();

  // Methods
  const init = (props) => {
    view.render("modal_content", mockup(props));

    view.isDisplayed("modal_wrapper", "hide-it");
  };
  function handleClick(e) {
    const target = e.target;
    const id = e.target.id;
    const value = e.target.value;
    const _id = target.dataset.id;
    const _value = target.dataset.value;

    const view = new View();

    switch (_id) {
      case "close_modal":
        console.log(_id);
        view.isDisplayed("modal_wrapper", "hide-it");
        break;
      case "close_modal_login":
        console.log("HERE");
        view.isDisplayed("modal_wrapper", "hide-it");

        break;
    }
  }
  // VIEWS

  const mockup = (props) => {
    return `<div class="cart-notification">
        ${closeBtn()}
        ${item(props)}
        ${actionBtn()}
        <div class="line-divider"></div>
      </div>`;
  };

  function closeBtn() {
    return ` <button data-id="close_modal" class="close-modal">
          <ion-icon
            class="icon-md"
            data-id="close_modal"
            name="close-outline"
          ></ion-icon>
        </button>`;
  }

  function item({id, image, title, price, description}) {
    let short_description = description;
    let alt = image?.split("/")[2]?.split(".")[0];
    return `<div class="cart-item-row">
          <div class="img-thumbnail content-is-loading">
            <img
              data-id="img_slider"
              data-value="${id}"
              src="${image}"
              alt="${alt}"
              class="img-cover"
            /></div>
          ${info({title, short_description, price})}
        </div>`;
  }

  function info({title, short_description, price}) {
    return `<div class="product-id">
      <h2>${title}</h2>
      <p>${short_description}</p>
      <p>R${price}</p>
    </div>`;
  }

  function actionBtn() {
    return `<div class="btn-row">
            <button data-id="close_modal" class="btn btn-border  btn-long">
              Continue Shopping
            </button>
            <a data-id="link" href="/cart" class="btn btn-long">
              View Bag
            </a>
          </div>`;
  }

  return {
    init,
    handleClick,
  };
})();

window.addEventListener("click", CartNotification.handleClick);

export default CartNotification;
