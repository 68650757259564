import {globalState} from "../Model";

let baseUrl = undefined;

const MODE = "prod";

if (MODE === "prod") {
  baseUrl = "https://wellnesswithbridgette-backend.onrender.com/api/v1";
} else {
  baseUrl = "http://localhost:8080/api/v1";
}

export async function getApi(url) {
  try {
    const res = await fetch(baseUrl + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const resData = await res.json();

    return resData;
  } catch (error) {
    console.log(error);
  }
}

export async function api(url, method, data) {
  try {
    const pathname = baseUrl + url;

    if (method === "GET") {
    } else if (method === "POST") {
      const res = await fetch(pathname, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const resData = await res.json();

      return resData;
    }
  } catch (error) {
    console.log(error);
  }
}
