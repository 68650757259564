import View from "../../../view/View.js";
import {globalState, setState} from "../../../model/model.js";

const ShippingForm = (function () {
  const state = {
    shipping:
      JSON.parse(localStorage.getItem(globalState.shop.shippingId)) ??
      new Object(globalState.shop.shipping),
    localShipping() {
      return (
        JSON.parse(localStorage.getItem(globalState.shop.shippingId)) ?? {}
      );
    },
  };
  const view = new View();

  const init = (props) => {
    // view.render("", mockup(props));
    initShipping(state.localShipping());
  };

  function initShipping(props) {
    if (!props?.email) {
      return;
    }

    for (let name in props) {
      let el = view.elem(name);
      // console.log(el);

      if (el) {
        if (name === "province") {
          el.innerHTML = state.localShipping()[name];
        } else {
          el.value = state.localShipping()[name];
        }
        el.style.fontSize = "16pt";
      }
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    const target = e.target;
    const id = e.target.id;
    const value = e.target.value;
    const _id = target.dataset.id;
    const _value = target.dataset.value;

    const view = new View();

    switch (_id) {
      case "submit_shipping":
        if (
          state.shipping?.province === "Select Province" ||
          !state.shipping?.province
        ) {
          let errElem = view.elem("err_province");
          errElem.innerHTML = "";
          console.log(errElem);
          errElem.innerHTML = "Please select a province";

          return;
        }

        //  Store address to local
        localStorage.setItem(
          globalState.shop.shippingId,
          JSON.stringify(state.shipping)
        );

        // route to next page
        const next = view.elem("next__page");

        // next.dataset.id = "link";
        // next.dataset.value = "/confirm_details";
        // next.dataset.item = "/confirm_details";
        // next.href = "/confirm_details";

        console.log(next);
        next.click();

        e.preventDefault();

        break;

      default:
        break;
    }
  }
  function handleInputs(e) {
    const target = e.target;
    const id = e.target.id;
    const value = e.target.value;
    const _id = target.dataset.id;
    const _value = target.dataset.value;

    const view = new View();

    switch (_id) {
      case "inputs":
        for (let name in state.shipping) {
          if (name === id) {
            state.shipping[name] = value;
          }
        }

        // let errElem = view.elem("err_province");
        // errElem.innerHTML = "";
        // view.render("province", state.shipping[name]);
        // view.isDisplayed(`select_container_${name}`, "hide-it");

        break;

      default:
        break;
    }
  }

  function handleClick(e) {
    const target = e.target;
    const id = e.target.id;
    const name = e.target.name;
    const value = e.target.value;
    const _id = target.dataset.id;
    const _value = target.dataset.value;
    const item = target.dataset.item;

    const view = new View();

    switch (_id) {
      case "inputs":
        if (item === "province") {
          state.shipping[item] = _value?.toLowerCase();
          view.isDisplayed(`select_container_${item}`, "hide-it");
          view.elem("province").innerHTML = _value;

          let errElem = view.elem("err_province");
          errElem.innerHTML = "";
          return;
        }

        break;
      case "link_shipping":
        e.preventDefault();

        for (let name in state.shipping) {
          if (name === "") {
            return;
          }
        }

        break;

      default:
        break;
    }
  }

  const mockup = (props) => {};
  return {
    init,
    handleSubmit,
    handleInputs,
    handleClick,
  };
})();

window.addEventListener("input", ShippingForm.handleInputs);
window.addEventListener("submit", ShippingForm.handleSubmit);
window.addEventListener("click", ShippingForm.handleClick);

export default ShippingForm;
