import View from "../../view/View.js";

const CallToAction = (function () {
  const view = new View();

  const init = (props) => {
    document.querySelector("main").insertAdjacentHTML(
      "afterend",
      mockup({
        title: "Get A Free Wellness Session📅",
        link: "https://wellnesswithbridgetteform.netlify.app",
      })
    );
  };
  const mockup = ({link, title}) => {
    return `<a target="_blank" href="${link}" id="call_to_action" class="hide-it">
            ${title}
      </a>`;
  };
  return {
    init,
  };
})();

export default CallToAction;
