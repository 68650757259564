import {_products} from "./products.js";

export const _shop = {
  page: 1,
  cart:
    localStorage.getItem("wellnesswithbridget__cart__items_shop") ??
    JSON.stringify([]),
  cartId: "wellnesswithbridget__cart__items_shop",
  getLocalShipping() {
    return (
      JSON.parse(
        localStorage.getItem("wellnesswithbridget__shipping__items_shop")
      ) ?? JSON.stringify([])
    );
  },
  shippingId: "wellnesswithbridget__shipping__items_shop",
  productsPerPage: 10,
  selectedProduct: [],
  filteredProduct: [],
  paginatedProducts: [],
  shipping: {
    email: null,
    country: null,
    name: null,
    surname: null,
    address: null,
    apartment: null,
    city: null,
    province: null,
    postal: null,
    cell: null,
    saveInfo: false,
  },
  products: _products,
  filter: {
    category: "",
    selectedSizes: [],
    selectedFlavors: [],
    selectedPriceRange: {min: undefined, max: undefined},
    featured: false,
    sale: false,
  },
};
