export const _private = {
  title: "Privacy Policy",
  content: `
        <h3>1. The use of customer information</h3>

<p>
  Your personal information that you can or must fill in on our website, required or optional, is required for processing your order or for responding to your question or comment. Your name, address, telephone number, and e-mail address are examples of this. Your name and address details will only be used as the shipping address of your order. Your e-mail address will only be used for electronic communication between wellnesswithbridgette.com and you. You will receive an e-mail with the order confirmation after placing an order. Answers to your questions and/or comments will also mainly be made by e-mail. Finally, your telephone number is only used in case of occasional delays to your order. The information you provide is stored in the wellnesswithbridgette.com database. However, this only happens for purposes as set out in this policy.
</p>

<h3>2. Storage of the information</h3>

<p>
  Your personal details and your order details are stored in a secure database. Periodically these data will be cleaned and old data will be deleted. If you have questions about the data that we have stored from you, please contact us via the contact menu on our website. You will then receive the information registered by us as soon as possible. Insofar as your customer information is still available and you want to have it removed, you can give us an order through the same communication channel. Once this assignment is received, the data will be deleted.
</p>

<h3>3. Providing information to third parties</h3>

<p>
  Your personal information will not be sold, traded, or rented to third parties, authorities, or organizations. This means that information about your orders is not provided to third parties unless we are legally obliged to do so. You will be notified of this at an occurrence.
</p>

<h3>4. Exception in relation to information to third parties</h3>

<p>
  Herbalife International is, on a contractual basis, entitled to request personal data from customers of Independent Herbalife Distributors. This happens in exceptional cases and on an incidental basis. You will be notified of this at an occurrence.
</p>

<h3>5. Your permission</h3>

<p>
  By using our website you agree to the data we collect and use it in the manner described above.
</p>

<h3>6. Changes</h3>

<p>
  Changes to the Privacy Policy are clearly stated on our website.
</p>

<h3>7. Other</h3>

<p>
  If you have any questions about the Privacy Policy or its applications, please contact us via the contact menu on our website.
</p>


<h2>Satisfied or your money back</h2>
<p>Fast Delivery in 5-7 working days</p>
<h3>100% Herbalife Products</h3>
    
    `,
};
