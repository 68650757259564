class View {
  elem(id) {
    let el = document.getElementById(id);

    if (el) {
      return el;
    }
  }
  isDisplayed(id, hideClass) {
    this.elem(id).classList.toggle(hideClass);
  }
  render(id, mockup) {
    let el = this.elem(id);

    if (el) {
      el.innerHTML = mockup;
    }
  }
}

export default View;
