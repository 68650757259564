import View from "../../../view/View";

class _views extends View {
  constructor(props) {
    super(props);
  }

  mockup(props) {
    return `<div>
        <div id="editor"></div>
        <button data-id="save_content">Save</button>
      </div>`;
  }

  postInfo() {
    return `
       <div id="start_writing" class="start__writing">
            <button data-id="start_writing" class="start__writing-btn">
              <ion-icon class="start__writing-icon" name="arrow-forward-outline" data-id="start_writing"></ion-icon>
            </button>
          </div>
          <form data-id="writing_block_info_form" id="writing_block_info_form">
          <div class="input__container">
          <label for="title">Title</label>
          <input type="text" id="title" data-id="writing_inputs" required>
            </div>
          <div class="input__container">
          <label for="description">Description</label>
          <input type="text" id="description" data-id="writing_inputs" required>
            </div>
            <div class="input__container">
              <input hidden="" type="file" id="imgCover" data-id="writing_inputs-img" required>
              <label id="writing_inputs-img" data-id="writing_inputs-img" for="imgCover">
              <img src="./images/gallery.png" data-id="writing_inputs-img" id="img_cover" class="input__container-placeholder">
              </label>
              </div>
              </form>
              `;
  }
  saveScreen() {
    return `
    <div id="start_writing" class="start__writing">
         <button data-id="close_toolkit_header" class="start__writing-btn">
          <ion-icon data-id="close_toolkit_header" class="close_toolkit_header-close" name="close-outline"></ion-icon>
         </button>
       </div>
    
    <form  data-id="save_content" id="writing_block_info_form">
    <div class="input__container">
              <label for="post_name">SaveAs:</label>
              <input type="text"  id="post_name" data-id="save_file_inputs" required="">
            </div>
          <div id="start_writing" class="start__writing">
              <button class="editor__btn-save" type="submit" >
            <span>Save</span>
            <ion-icon name="save-outline"></ion-icon>
          </button>
          </div>
          </form>
    `;
  }
}
export default _views;
