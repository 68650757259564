import CreateStory from "../components/CreateStory/CreateStory.js";
import LandingPages from "../components/landingPages/LandingPages.js";
import Login from "../components/login/Login.js";
import {globalState} from "../model/Model.js";
import {getApi} from "../model/api/api.js";
import {getAuthApi, postAuthApi} from "../model/api/auth.js";
import Blog from "../screen/Blog.js";

import BusinessOpportunity from "../screen/BusinessOpportunity.js";
import Cart from "../screen/Cart.js";
import ConfirmDetails from "../screen/ConfirmDetails.js";
import Details from "../screen/Details.js";

import Home from "../screen/Home.js";
import Payments from "../screen/Payments.js";
import Shipping from "../screen/Shipping.js";
import Shop from "../screen/Shop.js";

import {QuillDeltaToHtmlConverter} from "quill-delta-to-html";

async function routing(path, route, props) {
  // const targetRoute = event.target.getAttribute("href");

  // Update the URL and load the corresponding content
  history.pushState({}, route, window.location.origin + route);

  router(path, props);
}

function hideIt(id, hide) {
  const elem = document.getElementById(id);

  if (hide) {
    if (!elem?.classList?.contains("hide-it")) {
      elem.classList.add("hide-it");
    }
    return;
  }

  elem.classList.remove("hide-it");
}

async function router(path, props) {
  const body = document.querySelector("body");

  const writeBlog = document.getElementById("write_block");

  if (!writeBlog?.classList?.contains("hide-it")) {
    writeBlog.classList.add("hide-it");
  }
  body.style.overflowY = "scroll";

  switch (path) {
    case "/":
      new Home().init(props?.home);
      break;
    case "/blog":
      try {
        const path = window.location.search;
        const _id = path?.split("&")[1]?.split("=")[1];

        const result = await getApi(`/stories/posts/post/${_id}`);

        let cfg = {
          multiLineBlockquote: true,
          inlineStyles: true,
          multiLineParagraph: true,
          encodeHtml: true,
        };

        let converter = new QuillDeltaToHtmlConverter(
          result?.data?.post?.content?.ops,
          cfg
        );

        let html = converter.convert();
        // view.elem("header")?.classList.add("hide-it");

        new Blog().init(html);
      } catch (error) {
        console.log(error);
      }

      break;
    case "/shop":
      new Shop().init(props?.shop);
      break;
    case "/product":
      console.log("HERE");
      new Details().init(props);
      break;
    case "/cart":
      new Cart().init(props?.shop);
      break;
    case "/shipping":
      new Shipping().init(props?.shop);
      break;
    case "/confirm_details":
      console.log(props);
      new ConfirmDetails().init(props);
      break;
    case "/payments":
      console.log(props);
      new Payments().init(props);
      break;
    case "/businessopportunity":
      new BusinessOpportunity().init();

      break;
    case "/weightgain":
      console.log("here");
      LandingPages.init("weight-gain");
      break;
    case "/accounts":
      body.style.overflowY = "hidden";
      document.querySelector("header").style.display = "none";
      document.getElementById("header_top").style.display = "none";

      if (!localStorage.getItem("mamakhe_s_tokens")) {
        return Login.init("login");
      }

      const results = await postAuthApi(
        "/auth/tokenverification",
        localStorage.getItem("mamakhe_s_tokens")
      );

      if (results?.status === "fail") {
        document.getElementById("modal_wrapper").classList.remove("hide-it");

        document.getElementById(
          "modal_content"
        ).innerHTML = `<div style="margin: 40px">
          <p>Session has expired, please login again</p>
          <button class="btn btn-sd" data-id="close_modal">OK</button>
        </div>`;
        return Login.init("login");
      }

      CreateStory.init();
      writeBlog.classList.remove("hide-it");
      document.querySelector(".take__quiz").innerHTML = `
         <div class="m_editor__footer" id="editor_footer">
        <button class="editor__btn-save" data-id="save_content-prep">
          <span data-id="save_content-prep">Save</span>
          <ion-icon data-id="save_content-prep" name="save-outline"></ion-icon>
        </button>
        <button class="editor__btn-publish" data-id="publish_content">
          <span data-id="publish_content">Publish</span>
          <ion-icon data-id="publish_content" name="send-outline"></ion-icon>
        </button>
      </div>
      `;

      break;
  }
}

export {routing, router};
