import View from "../../../view/View.js";

class LoginViews extends View {
  constructor(props) {
    super(props);
  }

  //   init(props) {}

  mockup(credits) {
    return `
    <div id="landing_page" >
     <div class="header">
        <div class="fg">
        <img src="https://img.freepik.com/free-photo/exercising-with-fitness-ball-side-view-wooman-sitting-fitness-ball-doing-exercises-with-dumbells_613910-2499.jpg" class="img-cover" alt="wellness with bridgette" /></div>
        </div>
        <div class="body">
        <h1>Herbalife weight gain program</h1>
        <p>have you been struggling to put on weight and feeling frustrated with your current physique.Struggling to Find the Right Nutrition? We know that gaining weight isn't just about eating more; it's about nourishing your body with the right nutrients.</p>
        <div class="bridge__img">

        </div>
        <p>Are you struggling to find the right balance of proteins, fats, and carbohydrates? <br/><br/>Feeling overwhelmed by all the conflicting advice out there? We've got your back.</p>

        <p>Take our survey to learn more about the herbalife personalised nutrition and how it can help you</p>
        <div>
        <a style="margin: 50px 0; max-width: 300px" href="https://form.jotform.com/231385290189563" target="_blank" class="btn btn-long">Weight-Gain Survey</a>
        
        </div>


     </div>
     <div class="footer"></div>
    </div>
        `;
  }

  viderWrapper(url, style) {
    return `
         <div class="video-wrapper ${style}">
            <iframe
              class="form-iframe"
              src="${url}"
              title="Business opportunity for moms (promotion video)"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
    `;
  }
}
export default LoginViews;
