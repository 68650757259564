import View from "../view/View.js";

class BlogPosts extends View {
  constructor(props) {
    super(props);
  }

  init(props, url) {
    this.render("blog_post_container", this.mockup(props));
  }

  mockup(props, url) {
    return props
      ?.map((item) => {
        if (
          item?.headline ===
          window.location?.search?.split("=")[1]?.split("-").join(" ")
        ) {
          return this.blogPost(item);
        }
      })
      .join(``);
  }

  blogPost({
    headline,
    image,
    datePublished,
    author,
    publisher,
    description,
    articleBody,
  }) {
    return `<section id="blog__post">
        <div class="header">
          <h3>${publisher?.name}</h3>
          <h1>${headline}</h1>
          <img data-src="${image[0]}" alt="wellnesswithbridgette blog" style="width: 100%" />
          <p>${description}</p>
          <div class="author-section">
          <author style="margin: 1px !important; padding: 1px; font-weight: bold">${author?.name}</author>
          <span style="margin: 1px !important; padding: 1px">${author?.title}</span>
          <span style="margin: 1px !important; padding: 1px">${datePublished}</span>
          </div>
        </div>
        <div class="body">

         ${articleBody}
        </div>
        <div class="header"></div>
      </section>`;
  }
}
export default BlogPosts;
