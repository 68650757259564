// import {globalState, setState} from "../../../model/model.js";

import "./Login.scss";
import {_ev} from "./events/_ev";

import LoginViews from "./views/_views";

const Login = (function () {
  const state = {};

  const view = new LoginViews();

  const handlers = new _ev();

  const init = (type) => {
    view.render("app", view.mockup(type));
    view.elem("splash_screen").classList.add("hide-it");
  };

  return {
    init,
    handleSubmit: handlers.handleSubmit,
  };
})();

window.addEventListener("submit", Login.handleSubmit);

export default Login;
