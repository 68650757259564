import {globalState} from "../../../../model/model.js";
import View from "../../../../view/View.js";
import _globalShop from "../../_globalShop.js";
import CartIcon from "../CartIcon.js";

const Quantity = (function () {
  const state = {
    quantity: 1,
    cart() {
      return JSON.parse(localStorage.getItem(globalState.shop.cartId));
    },
  };

  // Methods
  function getQty(id) {
    return (
      state.cart()?.filter((el) => el?.id === id)?.[0]?.quantity ??
      state.quantity
    );
  }
  function handleClick(e) {
    const target = e.target;
    const id = e.target.id;
    const value = e.target.value;
    const _id = target.dataset.id;
    const _value = target.dataset.value;
    const item = target.dataset.item;

    const view = new View();

    switch (_id) {
      case "quantity_down":
        let down = getQty(item);

        console.log(down);

        if (state.quantity > 1) {
          down--;
          state.quantity = down;
        }

        state.quantity = down;

        _globalShop.setGlobalQuantity(state.quantity);

        // updateCartQuantity
        if (state.cart()?.length > 0) {
          updateCartQuantity(item, "quantity");
        }
        view.render(`quantity_val_${item}`, state.quantity);
        break;
      case "quantity_up":
        let up = getQty(item);

        if (up < 10) {
          up++;
          state.quantity = up;
        }

        console.log(up);
        state.quantity = up;

        _globalShop.setGlobalQuantity(state.quantity);

        // if (!getQty(item)) {
        // }
        // updateCartQuantity
        if (state.cart()?.length > 0) {
          updateCartQuantity(item, "quantity");
        }
        view.render(`quantity_val_${item}`, state.quantity);
        break;
      case "delete_cart_item":
        deleteItem(_value);

        if (state.cart()?.length === 0) {
          view.render(
            "cart_table_body",
            `<h2 class="no__item">🛒 You Have No Items In Your Cart.</h2>`
          );

          view.elem("checkout_btn").classList.remove("hide-it");
          view.elem("checkout_link").classList.add("hide-it");

          return;
        }

        break;
    }
  }

  function updateCartQuantity(id, target) {
    console.log(target);
    let tempState = [];
    state.cart().forEach((item) => {
      if (item?.id === id) {
        item.quantity = state.quantity;
        console.log(window.location.pathname);
        if (window.location.pathname !== "/product") {
          view.render(
            `cart_item_price_${id}`,
            "R" + state.quantity * item.price
          );
        }
      }

      tempState.push(item);
    });

    _globalShop.saveToLocal(tempState, target);
  }

  function deleteItem(id) {
    let cartItems = state.cart();

    const index = cartItems?.findIndex((el) => el.id === id);

    cartItems.splice(index, 1);

    let elItem = view.elem(`cart_${id}`);
    elItem.style.transform = `translateX(-200%)`;
    elItem.style.height = "0px !important";

    setTimeout(() => {
      elItem.classList.add("hide-it");
    }, 500);

    _globalShop.saveToLocal(cartItems, "quantity");
  }

  // Views

  const view = new View();

  const mockup = (id, quantity) => {
    if (state.cart()?.length === 0) {
      view?.elem("checkout_btn")?.classList.remove("hide-it");
      view?.elem("checkout_link")?.classList.add("hide-it");
    } else {
      view?.elem("checkout_btn")?.classList.add("hide-it");
      view?.elem("checkout_link")?.classList.remove("hide-it");
    }
    return quantityCounter(id, quantity);
  };

  function quantityCounter(id, quantity) {
    state.quantity = quantity;

    return ` <div class="quantity-counter">
        <button data-value="${quantity}" data-item="${id}" data-id="quantity_down" class="icon-sm">
    
            -
        
        </button>
        <p id="quantity_val_${id}">${getQty(id)}</p>
        <button data-value="${quantity}" data-item="${id}" data-id="quantity_up" class="icon-sm">
       
            +
     
        </button>
      </div>`;
  }

  return {
    mockup,
    handleClick,
  };
})();

window.addEventListener("click", Quantity.handleClick);

export default Quantity;
