import View from "../../../../view/View.js";
import {globalState, setState} from "../../../../model/model.js";
import CartView from "./CartView.js";

const CartTable = (function () {
  const view = new View();

  const init = () => {
    let tempCart = JSON.parse(localStorage.getItem(globalState.shop.cartId));

    console.log(tempCart?.length);

    if (tempCart?.length === 0 || !tempCart) {
      view.render(
        "cart_table_body",
        `<h2 class="no__item">🛒 You Have No Items In Your Cart.</h2>`
      );

      return;
    }
    view.render("cart_table_body", new CartView().mockup(tempCart));
  };

  return {
    init,
  };
})();

export default CartTable;
