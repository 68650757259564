import {globalState} from "../Model";

let baseUrl = undefined;

const MODE = "prod";
console.log(MODE);

if (MODE === "prod") {
  baseUrl = "https://wellnesswithbridgette-backend.onrender.com/api/v1";
} else {
  baseUrl = "http://localhost:8080/api/v1";
}

console.log(baseUrl);

export async function getAuthApi(url, token) {
  try {
    const res = await fetch(baseUrl + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const resData = await res.json();

    return resData;
  } catch (error) {
    console.log(error);
  }
}
export async function postAuthApi(url, token, data) {
  try {
    const res = await fetch(baseUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    const resData = await res.json();

    return resData;
  } catch (error) {
    console.log(error);
  }
}
export async function patchAuthApi(url, token, data) {
  try {
    const res = await fetch(baseUrl + url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const resData = await res.json();

    return resData;
  } catch (error) {
    console.log(error);
  }
}

export async function postAuthImg(url, token, formData) {
  try {
    const res = await fetch(baseUrl + url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const resData = await res.json();

    return resData;
  } catch (error) {
    console.log(error.message, "LOGO");
  }
}
